import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginAccessGuard } from './guards/login.guard';
import { LoginComponent } from './components/login/login.component';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { UserProfileComponent } from './components/user/user-profile/user-profile.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { AddUserComponent } from './components/user/add-user/add-user.component';
import { SubmitOvertimeComponent } from './components/overtime/overtime-submit/submit-overtime.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { UsersComponent } from './components/user/users/users.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { LeavingDetailComponent } from './components/leaving-detail/leaving-detail.component';
import { SalaryDetailComponent } from './components/salary-detail/salary-detail.component';
import { UserProfileUpdateComponent } from './components/user/user-profile-update/user-profile-update.component';
import { AdminAccessGuard } from './guards/admin.guard.';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SettingComponent } from './components/setting/setting.component';
import { AddProjectComponent } from './components/project/add-project/add-project.component';
import { ResetPasswordComponent } from './components/reset-passcode/reset-passcode.component';
import { ListProjectComponent } from './components/project/list-project/list-project.component';
import { OvertimeListComponent } from './components/overtime/overtime-list/overtime-list.component';
import { ActivateUserComponent } from './components/user/activate-user/activate-user.component';
import { RateOverTimesComponent } from './components/rateovertimes/rateovertimes.component';
import { ConfirmMailComponent } from './components/confirm-mail/confirm-mail.component';
import { NotifyListComponent } from './components/notify-list/notify-list.component';
import { CashAdvanceComponent } from './components/cash-advance/cash-advance.component';
import { ViewProjectComponent } from './components/project/view-project/view-project.component';
import { OvertimeViewComponent } from './components/overtime/overtime-view/overtime-view.component';
import { MaintainanceComponent } from './components/maintainance/maintainance.component';
import { MenuItems } from 'src/app/models/enums';
import { HomepageAdminComponent } from './components/homepage-admin/homepage-admin.component';
import { RewardListComponent } from './components/rewards/reward-list/reward-list.component';
import { RewardComposeComponent } from './components/rewards/reward-compose/reward-compose.component';
import { RewardViewComponent } from './components/rewards/reward-view/reward-view.component';
import { MeetingComponent } from './components/meeting/meeting.component';
import { ListDepartmentComponent } from './components/department/list-department/list-department.component';
import { AddDepartmentComponent } from './components/department/add-department/add-department.component';
import { ViewDepartmentComponent } from './components/department/view-department/view-department.component';
import { PockerComponent } from './components/pocker/pocker.component';
import { RoomDetailComponent } from './components/pocker/room-detail/room-detail.component';
import { APP_ROUTER } from './app.constants';
import { WorkRemoteComponent } from './components/work-remote/work-remote.component';
import { ListCustomerComponent } from './components/customer/list-customer/list-customer.component';
import { AddCustomerComponent } from './components/customer/add-customer/add-customer.component';
import { DetailProjectComponent } from './components/project/detail-project/detail-project.component';

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: '',
        component: MainLayoutComponent,
        canActivate: [LoginAccessGuard],
        children: [
            {
                path: '',
                component: HomepageComponent,
                data: { title: "Trang Chủ", menuGroup: MenuItems.Home }
            },
            {
                path: 'admin-dashboard',
                component: HomepageAdminComponent,
                data: { title: "Trang Chủ", menuGroup: MenuItems.Home }
            },
            {
                path: 'add-user',
                component: AddUserComponent,
                canActivate: [AdminAccessGuard],
                data: { title: "Thêm Nhân Viên", menuGroup: MenuItems.Users }
            },
            {
                path: 'list-project',
                component: ListProjectComponent,
                canActivate: [LoginAccessGuard],
                data: { title: "Danh Sách Dự Án", menuGroup: MenuItems.Project }
            },
            {
                path: 'view-project/:id',
                component: ViewProjectComponent,
                data: { title: "Chi Tiết Dự Án", menuGroup: MenuItems.Project }
            },
            {
                path: 'edit-project/:id',
                component: AddProjectComponent,
                data: { title: "Chỉnh Sửa Dự Án", menuGroup: MenuItems.Project }
            },
            {
                path: 'detail-project/:id',
                component: DetailProjectComponent,
                data: { title: "Thông tin chi tiết dự án", menuGroup: MenuItems.Project }
            },
            {
                path: 'add-project',
                component: AddProjectComponent,
                canActivate: [AdminAccessGuard],
                data: { title: "Thêm Dự Án", menuGroup: MenuItems.Project }
            },
            {
                path: 'list-department',
                component: ListDepartmentComponent,
                canActivate: [LoginAccessGuard],
                data: { title: "Danh Sách Phòng Ban", menuGroup: MenuItems.Department }
            },
            {
                path: 'add-department',
                component: AddDepartmentComponent,
                canActivate: [LoginAccessGuard],
                data: { title: "Thêm Phòng Ban", menuGroup: MenuItems.Department }
            },
            {
                path: 'view-department/:id',
                component: ViewDepartmentComponent,
                data: { title: "Chi Tiết Phòng Ban", menuGroup: MenuItems.Department }
            },
            {
                path: 'edit-department/:id',
                component: AddDepartmentComponent,
                canActivate: [AdminAccessGuard],
                data: { title: "Chỉnh Sửa Phòng Ban", menuGroup: MenuItems.Department }
            },
            {
                path: 'user-profile',
                component: UserProfileComponent,
                data: { title: "Thông Tin Cá Nhân", menuGroup: MenuItems.Users }
            },
            {
                path: 'user-profile/:id',
                component: UserProfileComponent,
                data: { title: "Thông Tin Nhân Viên", menuGroup: MenuItems.Users }
            },
            {
                path: 'user-profile-update',
                component: UserProfileUpdateComponent,
                data: { title: "Chỉnh Sửa Thông Tin Cá Nhân", menuGroup: MenuItems.Users }
            },
            {
                path: 'user-profile-update/:id',
                component: UserProfileUpdateComponent,
                data: { title: "Chỉnh Sửa Thông Tin Nhân Viên", menuGroup: MenuItems.Users }
            },
            {
                path: 'submit-overtime',
                component: SubmitOvertimeComponent,
                data: { title: "Tăng Ca", menuGroup: MenuItems.OverTime }
            },
            {
                path: 'overtime-list',
                component: OvertimeListComponent,
                data: { title: "Danh Sách Các Giờ Tăng Ca", menuGroup: MenuItems.OverTime }
            },
            {
                path: 'users',
                component: UsersComponent,
                data: { title: "Danh Sách Nhân Viên", menuGroup: MenuItems.Users }
            },
            {
                path: 'leaving-detail',
                component: LeavingDetailComponent,
                data: { title: "Nghỉ Phép", menuGroup: MenuItems.DayOff }
            },
            {
                path: 'salary-detail',
                component: SalaryDetailComponent,
                data: { title: "Chi Tiết Bảng Lương", menuGroup: MenuItems.Salary }
            },
            {
                path: 'setting',
                component: SettingComponent,
                data: { title: "Cài Đặt", menuGroup: MenuItems.Setting }
            },
            {
                path: 'meeting',
                component: MeetingComponent,
                data: { title: "Chi tiết các buổi họp", menuGroup: MenuItems.Meeting }
            },
            {
                path: 'salary-detail/:id',
                component: SalaryDetailComponent,
                data: { title: "Bảng Lương Nhân Viên", menuGroup: MenuItems.Salary }
            },
            {
                path: 'rateovertimes',
                component: RateOverTimesComponent,
                data: { title: "Tỷ Giá Tăng Ca", menuGroup: MenuItems.RateOvertime }

            },
            {
                path: 'notify-list',
                component: NotifyListComponent,
                data: { title: "Danh Sách Thông Báo", menuGroup: MenuItems.Notification }
            },
            {
                path: 'cash-advance',
                component: CashAdvanceComponent,
                data: { title: "Tạm Ứng Lương", menuGroup: MenuItems.CashAdvance }
            },
            {
                path: 'overtime-view',
                component: OvertimeViewComponent,
                data: { title: "Xem Chi Tiết Tăng Ca", menuGroup: MenuItems.OverTime }
            },
            {
                path: 'reward-list',
                component: RewardListComponent,
                data: { title: "Danh Sách Khen Thưởng", menuGroup: MenuItems.Reward }
            },
            {
                path: 'reward-list/:status',
                component: RewardListComponent,
                data: { title: "Khen Thưởng Chưa Gửi", menuGroup: MenuItems.Reward }
            },
            {
                path: 'reward-list/:status/:sent',
                component: RewardListComponent,
                data: { title: "Khen Thưởng Đã Gửi", menuGroup: MenuItems.Reward }
            },
            {
                path: 'reward-compose',
                component: RewardComposeComponent,
                data: { title: "Soạn Khen Thưởng", menuGroup: MenuItems.Reward }
            },
            {
                path: 'reward-view/:id',
                component: RewardViewComponent,
                data: { title: "Chỉnh Sửa Nội Dung Khen Thưởng", menuGroup: MenuItems.Reward }
            },
            // {
            // path: 'Policy',
            // component: PolicyComponent,
            // data: { title: "Chính sách ", menuGroup: MenuItems.Policy }
            // },
            {
                path: 'scrum-pocker/:id',
                component: PockerComponent,
                data: { title: "Danh Sách Room", menuGroup: MenuItems.Project }
            },
            {
                path: 'room/:id',
                component: RoomDetailComponent,
                data: { title: "Ước Tính Thời Gian", menuGroup: MenuItems.Project }
            },
            {
                path: 'work-remote',
                component: WorkRemoteComponent,
                data: { title: "Làm Việc Từ Xa", menuGroup: MenuItems.WorkRemote }
            },
            {
                path: 'list-customer',
                component: ListCustomerComponent,
                data: { title: "Khách Hàng", menuGroup: MenuItems.Customer }
            },
            {
                path: 'add-customer',
                component: AddCustomerComponent,
                data: { title: "Thêm Khách Hàng", menuGroup: MenuItems.Customer }
            },
            {
                path: 'edit-customer/:id',
                component: AddCustomerComponent,
                data: { title: "Chỉnh Sửa Khách Hàng", menuGroup: MenuItems.Customer }
            },
        ]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'change-password',
        component: ChangePasswordComponent
    },
    {
        path: 'change-passcode',
        component: ResetPasswordComponent
    },
    {
        path: 'confirm-mail/:email',
        component: ConfirmMailComponent
    },
    {
        path: 'confirm-mail',
        component: ConfirmMailComponent
    },
    {
        path: 'change-passcode/:token',
        component: ResetPasswordComponent
    },
    {
        path: 'activate-user',
        component: ActivateUserComponent
    },
    {
        path: 'activate-user/:token',
        component: ActivateUserComponent
    },
    {
        path: 'maintainance',
        component: MaintainanceComponent
    },
    { path: '404', component: NotFoundComponent },
    { path: '**', redirectTo: '/404' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
