<div class="row">
  <div class="col-lg-6">

    <div class="card-box">
      <h4 class="header-title m-t-0">Tỷ giá và Token</h4>
      <p class="text-muted font-14 m-b-20">
      </p>
      <form #form="ngForm" (submit)="systemCurrencyTokenSetting(form)" autocomplete="off">
        <div class="form-group">
          <app-text-box class="app-text" type="number" name="curencyExchange"
            [(ngModel)]="systemCurrencyTokenModel.curencyExchange" title="Tỷ giá hiện tại" min="1000">
            <div invalidmessage="curencyExchange">
              <p *invalidType="'min'">Tỷ giá nhỏ nhất là 1000 VNĐ!!</p>
            </div>
          </app-text-box>
        </div>
        <div class="form-group">
          <app-text-box class="app-text" type="number" name="dateExpireToken"
            [(ngModel)]="systemCurrencyTokenModel.dateExpireToken" title="Thời gian hết hạn token (ngày)" min="1">
            <div invalidmessage="dateExpireToken">
              <p *invalidType="'min'">Thời gian hết hạn Token ít nhất là 1 ngày !!</p>
            </div>
          </app-text-box>
        </div>
        <div class="form-group text-right m-b-0">
          <button class="btn btn-custom submit-btn waves-effect waves-light mr-2">
            Lưu
          </button>
          <button class="btn btn-light waves-effect m-l-5">
            Mặc định
          </button>
        </div>
      </form>
    </div> <!-- end card-box -->

    <div class="card-box">
      <h4 class="header-title m-t-0">Email hệ thống và nhắc lịch</h4>
      <p class="text-muted font-14 m-b-20">
      </p>
      <form #formEmailReminder="ngForm" (submit)="systemEmailRemindSetting(formEmailReminder)" autocomplete="off">

        <div class="form-group">
          <app-text-box class="app-text" type="text" name="smtpHost" [(ngModel)]="systemEmailRemindModel.smtpHost"
            title="SMTP host" required="true">
          </app-text-box>
          <div invalidmessage="smtpHost">
            <p *invalidType="'required'">Vui lòng nhập SMTP Host</p>
          </div>
        </div>

        <div class="form-group">
          <app-text-box class="app-text" type="number" name="port" [(ngModel)]="systemEmailRemindModel.port"
            title="SMTP port" min="1" max="65535" required="true">
            <div invalidmessage="port">
              <p *invalidType="'min'">Vui lòng nhập cổng kết nối trong khoảng 1 - 65535</p>
              <p *invalidType="'max'">Vui lòng nhập cổng kết nối trong khoảng 1 - 65535</p>
            <p *invalidType="'required'">Vui lòng nhập SMTP Port</p>

            </div>
          </app-text-box>
        </div>

        <div class="form-group">
          <app-text-box class="app-text" type="text" name="sendMailFrom" [(ngModel)]="systemEmailRemindModel.sendMailFrom"
            title="Send email messages from" email="true" autocomplete="new-email" required="true">
            <div invalidmessage="sendMailFrom">
              <p *invalidType="'email'">Vui lòng nhập địa chỉ thư điện tử hợp lệ</p>
              <p *invalidType="'required'">Vui lòng nhập địa chỉ gửi</p>
            </div>
          </app-text-box>
        </div>

        <div class="form-group">
          <app-text-box class="app-text" type="text" name="userName" [(ngModel)]="systemEmailRemindModel.userName"
            title="SMTP login" required="true">
            <div invalidmessage="userName">
              <p *invalidType="'required'">Vui lòng nhập SMTP login</p>
            </div>
          </app-text-box>
        </div>

        <div class="form-group">
          <app-text-box class="app-text" type="password" name="password" [(ngModel)]="systemEmailRemindModel.password"
            title="SMTP password" autocomplete="new-password" required="true">
            <div invalidmessage="password">
              <p *invalidType="'required'">Vui lòng nhập SMTP password</p>
            </div>
          </app-text-box>
        </div>

        <div class="form-group">
          <app-text-box class="app-text" type="number" name="timeRemind" [(ngModel)]="systemEmailRemindModel.timeRemind"
            title="Nhắc lịch (phút)" min="1">
          </app-text-box>
          <div invalidmessage="timeRemind">
            <p *invalidType="'min'">Vui lòng nhập thời hạn lớn hơn 1 phút!!!</p>
          </div>
        </div>

        <div class="form-group text-right m-b-0">
          <button class="btn btn-custom submit-btn waves-effect waves-light mr-2">
            Lưu
          </button>
          <button class="btn btn-light waves-effect m-l-5">
            Mặc định
          </button>
        </div>
      </form>
    </div> <!-- end card-box -->

    <div class="card-box">
      <h4 class="header-title m-t-0">Ngày thành lập công ty</h4>
      <p class="text-muted font-14 m-b-20">
      </p>
      <form #formSetupCompany="ngForm" (submit)="updateSetupCompany(formSetupCompany)" autocomplete="off">
        <div class="form-group row d-flex align-items-center">
          <label class="col-sm-4 col-form-label">Ngày thành lập công ty</label>
          <div class="col-sm-8">
              <div class="input-group">
                  <input type="text" name="celebratorDate" placeholder="Ngày thành lập công ty"
                      [(ngModel)]="setupCompany.celebratorDate" class="form-control"
                      #dpr="bsDatepicker" [bsConfig]="bsConfig" required bsDatepicker>
                  <div class="input-group-prepend bg-primary border-primary"
                      (click)="dpr.toggle()" [attr.aria-expanded]="dpr.isOpen">
                      <span class="input-group-text bg-primary border-primary">
                          <i class="mdi mdi-calendar-text text-white"></i>
                      </span>
                  </div>
                  <div invalidmessage="celebratorDate">
                      <p *invalidType="'required'">Bạn vui lòng chọn ngày thành lập công ty</p>
                  </div>
              </div>
          </div>
        </div>

        <div class="form-group text-right m-b-0">
          <button class="btn btn-custom submit-btn waves-effect waves-light">
            Lưu
          </button>
        </div>
      </form>
    </div> <!-- end card-box -->

    <div class="card-box">

      <h4 class="header-title m-t-0">Hiển thị</h4>
      <p class="text-muted font-14 m-b-20">
        Logo, ảnh nền và tiêu đề trang web
      </p>

      <div class="form-group">
        <app-text-box class="app-text" type="text" name="documentTitle" [(ngModel)]="documentTitle"
          title="Tiêu đề trang web">
        </app-text-box>
      </div>

      <div class="form-group">
        <app-text-box class="app-text" type="text" name="footer" [(ngModel)]="footer" title="Footer">
        </app-text-box>
      </div>

      <div class="form-group ">
        <label class="label">
          Logo, Background, Favicon
        </label>
        <div class="row">
          <div class="col-lg-3 col-xl-4">
            <input type="file" class="file-upload-default" (change)="onChangeLogonLogo($event)" name="uploadedLogonLogo" hidden
              #logonLogoImageUpload>
            <div class="file-man-box tile-file" (click)="logonLogoImageUpload.click()">
              <h5 class="mb-0 text-overflow file-title">Logon Logo</h5>
              <div class="file-img-box">
                <app-shared-image [srcImg]="logonLogo | image"
                                  [altImg]="'icon'">
                </app-shared-image>
              </div>

            </div>
          </div>
          <div class="col-lg-3 col-xl-4">
            <input type="file" class="file-upload-default" (change)="onChangeLogo($event)" name="uploadedLogo" hidden
              #logoImageUpload>
            <div class="file-man-box tile-file" (click)="logoImageUpload.click()">
              <h5 class="mb-0 text-overflow file-title">Logo</h5>
              <div class="file-img-box">
                <app-shared-image [srcImg]="logo | image"
                                  [altImg]="'icon'">
                </app-shared-image>
              </div>

            </div>
          </div>
          <div class="col-lg-3 col-xl-4">
            <input type="file" class="img-cover file-upload-default" (change)="onChangeBackground($event)" hidden
              name="uploadedFile" #backgroundImageUpload>
            <div class="file-man-box tile-file" (click)="backgroundImageUpload.click()">
              <h5 class="mb-0 text-overflow file-title">Background</h5>
              <div class="file-img-box">
                <app-shared-image [srcImg]="background | image"
                                  [altImg]="'icon'">
                </app-shared-image>
              </div>

            </div>
          </div>
          <div class="col-lg-3 col-xl-4">
            <input type="file" class="img-cover file-upload-default" (change)="onChangeFavIcon($event)" hidden
              name="uploadedFavIcon" #favIconUpload>
            <div class="file-man-box tile-file" (click)="favIconUpload.click()">
              <h5 class="mb-0 text-overflow file-title">Favicon</h5>
              <div class="file-img-box">
                <app-shared-image [srcImg]="favIcon | image"
                                  [altImg]="'icon'">
                </app-shared-image>
              </div>

            </div>
          </div>
        </div>
      </div>


      <div class="form-group text-right m-b-0">
        <button class="btn btn-custom submit-btn waves-effect waves-light mr-2" (click)="systemLogoBackgroundSetting()">
          Lưu
        </button>
        <button class="btn btn-light waves-effect m-l-5">
          Mặc định
        </button>
      </div>

    </div> <!-- end card-box -->

    <div class="card-box">
      <h4 class="header-title m-t-0">Mẫu chứng từ</h4>
      <div class="row">
        <div class="col-lg-4 col-xl-6">
          <input type="file" class="img-cover file-upload-default" accept=".doc,.docx"
                 (change)="onChangeProbationaryContract($event)" hidden name="uploadedProbationaryContract"
                 #probationaryContractUpload>
          <div class="file-man-box tile-file">
            <h5 class="mb-0 text-overflow file-title">Hợp đồng thử việc</h5>
            <div class="file-img-box" (click)="probationaryContractUpload.click()">
              <app-shared-image [srcImg]="'assets/images/doc.svg'"
                                [altImg]="'icon'">
              </app-shared-image>
            </div>
            <a class="file-download" (click)="downloadContract(patternTemplateTypes.ProbationaryContract)"><i class="mdi mdi-download"></i> </a>
            <div class="file-man-title">
              <h5 class="mb-0 text-overflow">{{probationaryContract}}</h5>
              <p class="mb-0"><small>{{probationaryContractSize}} kb</small></p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-xl-6">
          <input type="file" class="img-cover file-upload-default" accept=".doc,.docx"
                 (change)="onChangeLaborContract($event)" hidden name="uploadedLaborContract" #laborContractUpload>
          <div class="file-man-box tile-file">
            <h5 class="mb-0 text-overflow file-title">Hợp đồng lao động</h5>
            <div class="file-img-box" (click)="laborContractUpload.click()">
              <app-shared-image [srcImg]="'assets/images/doc.svg'"
                                [altImg]="'icon'">
              </app-shared-image>
            </div>
            <a class="file-download" (click)="downloadContract(patternTemplateTypes.LaborContract)"><i class="mdi mdi-download"></i> </a>
            <div class="file-man-title">
              <h5 class="mb-0 text-overflow">{{laborContract}}</h5>
              <p class="mb-0"><small>{{laborContractSize}} kb</small></p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-xl-6">
          <input #collaboratorContractUpload
                 type="file"
                 class="img-cover file-upload-default"
                 accept=".doc,.docx"
                 name="uploadedCollaboratorContract"
                 hidden
                 (change)="onChangeCollaboratorContract($event)">
          <div class="file-man-box tile-file">
            <h5 class="mb-0 text-overflow file-title">Hợp đồng dịch vụ</h5>
            <div class="file-img-box" (click)="collaboratorContractUpload.click()">
              <app-shared-image [srcImg]="'assets/images/doc.svg'"
                                [altImg]="'icon'">
              </app-shared-image>
            </div>
            <a class="file-download" (click)="downloadContract(patternTemplateTypes.CollaboratorContract)"><i class="mdi mdi-download"></i> </a>
            <div class="file-man-title">
              <h5 class="mb-0 text-overflow">{{collaboratorContract}}</h5>
              <p class="mb-0"><small>{{collaboratorContractSize}} kb</small></p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-xl-6">
          <input type="file" class="img-cover file-upload-default" accept=".doc,.docx"
                 (change)="onChangeSubContract($event)" hidden name="uploadedSubContract" #subContractUpload>
          <div class="file-man-box tile-file">
            <h5 class="mb-0 text-overflow file-title">Phụ lục hợp đồng</h5>
            <div class="file-img-box" (click)="subContractUpload.click()">
              <app-shared-image [srcImg]="'assets/images/doc.svg'"
                                [altImg]="'icon'">
              </app-shared-image>
            </div>
            <a class="file-download" (click)="downloadContract(patternTemplateTypes.SubContract)"><i class="mdi mdi-download"></i> </a>
            <div class="file-man-title">
              <h5 class="mb-0 text-overflow">{{subContract}}</h5>
              <p class="mb-0"><small>{{subContractSize}} kb</small></p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-xl-6">
          <input type="file" class="img-cover file-upload-default" accept=".doc,.docx,.xlxs,.xlsx"
                 (change)="onChangeSalary($event)" hidden name="uploadedSalary" #salaryUpload>
          <div class="file-man-box tile-file">
            <h5 class="mb-0 text-overflow file-title">Bảng lương mẫu</h5>
            <div class="file-img-box" (click)="salaryUpload.click()">
              <app-shared-image [srcImg]="'assets/images/xls.svg'"
                                [altImg]="'icon'">
              </app-shared-image>
            </div>
            <a class="file-download" (click)="downloadContract(patternTemplateTypes.SalaryTemplate)"><i class="mdi mdi-download"></i> </a>
            <div class="file-man-title">
              <h5 class="mb-0 text-overflow">{{salary}}</h5>
              <p class="mb-0"><small>{{salarySize}} kb</small></p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-xl-6">
          <input type="file" class="img-cover file-upload-default" accept=".doc,.docx,.xlxs,.xlsx,.xls"
                 (change)="onChangeSalaryBank($event)" hidden name="uploadedSalaryBank" #salaryBankUpload>
          <div class="file-man-box tile-file">
            <h5 class="mb-0 text-overflow file-title">Bảng lương ngân hàng mẫu</h5>
            <div class="file-img-box" (click)="salaryBankUpload.click()">
              <app-shared-image [srcImg]="'assets/images/xls.svg'"
                                [altImg]="'icon'">
              </app-shared-image>
            </div>
            <a class="file-download" (click)="downloadContract(patternTemplateTypes.SalaryBankTemplate)"><i class="mdi mdi-download"></i> </a>
            <div class="file-man-title">
              <h5 class="mb-0 text-overflow">{{salaryBank}}</h5>
              <p class="mb-0"><small>{{salaryBankSize}} kb</small></p>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group text-right m-b-0">
        <button type="button" (click)="uploadFiles()"
          class="btn btn-custom submit-btn waves-effect waves-light">Lưu</button>
      </div>
    </div> <!-- end card-box -->

  </div>
  <div class="col-lg-6">

    <div class="card-box">
      <h4 class="header-title m-t-0">Sinh nhật</h4>
      <p class="text-muted font-14 m-b-20">
        Mẫu email sinh nhật
      </p>
      <div class="form-group">
        <div id="birthDayTemplate">
          <p></p>
        </div>
      </div>
      <div class="form-group text-right m-b-0">
        <button class="btn btn-custom submit-btn waves-effect waves-light mr-2"
          (click)="systemBirthdayTemplateSetting()">
          Lưu
        </button>
        <button type="reset" class="btn btn-light waves-effect m-l-5">
          Mặc định
        </button>
      </div>
    </div> <!-- end card-box -->

    <div class="card-box">
      <h4 class="header-title m-t-0">Nhắc nhở ngày nghỉ</h4>
      <p class="text-muted font-14 m-b-20">
        Mẫu email nhắc ngày nghỉ
      </p>
      <div class="form-group">
        <div id="remindDayOffTemplate">
          <p></p>
        </div>
      </div>
      <div class="form-group text-right m-b-0">
        <button class="btn btn-custom submit-btn waves-effect waves-light mr-2" (click)="saveRemindDayOffTemplate()">
          Lưu
        </button>
        <button type="reset" class="btn btn-light waves-effect m-l-5">
          Mặc định
        </button>
      </div>
    </div> <!-- end card-box -->

    <div class="card-box">
      <h4 class="header-title m-t-0">Bảng lương</h4>
      <p class="text-muted font-14 m-b-20">
        Mẫu email bảng lương
      </p>
      <div class="form-group">
        <div id="salaryTemplate">
          <p></p>
        </div>
      </div>
      <div class="form-group text-right m-b-0">
        <button class="btn btn-custom submit-btn waves-effect waves-light mr-2" (click)="saveSalaryTemplate()">
          Lưu
        </button>
        <button type="reset" class="btn btn-light waves-effect m-l-5">
          Mặc định
        </button>
      </div>
    </div> <!-- end card-box -->


    <div class="card-box">
      <h4 class="header-title m-t-0">NGÀY THÀNH LẬP</h4>
      <p class="text-muted font-14 m-b-20">
        Mẫu email chúc mừng ngày thành lập công ty
      </p>
      <div class="form-group">
        <div id="celebratorEmailTemplate">
          <p></p>
        </div>
      </div>
      <div class="form-group text-right m-b-0">
        <button class="btn btn-custom submit-btn waves-effect waves-light"
          (click)="saveSetupCompanyTemplateSetting()">
          Lưu
        </button>
      </div>
    </div> <!-- end card-box -->

    <div class="card-box">
      <h4 class="header-title m-t-0">Quên mật khẩu</h4>
      <p class="text-muted font-14 m-b-20">
        Mẫu email quên mật khẩu
      </p>
      <div class="form-group">
        <div id="forgotPasswordTemplate">
          <p></p>
        </div>
      </div>
      <div class="form-group text-right m-b-0">
        <button class="btn btn-custom submit-btn waves-effect waves-light mr-2" (click)="saveForgotPasswordTemplate()">
          Lưu
        </button>
        <button type="reset" class="btn btn-light waves-effect m-l-5">
          Mặc định
        </button>
      </div>
    </div> <!-- end card-box -->

  </div>
</div>
