<div class="account-pages">
  <!-- Begin page -->
  <div class="accountbg"
    [ngStyle]="{'background-image': 'url(' + background.backgroundImage + ')', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-position': 'center'}">

    <div class="wrapper-page account-page-full">
      <div class="card">
        <div class="card-block">

          <div class="account-box">

            <div class="card-box p-5">
              <h2 class="text-uppercase text-center pb-4">
                <a href="" class="text-success">
                  <span>
                    <app-shared-image [srcImg]="background.logo" [altImg]="'image'" [height]="72">
                    </app-shared-image>
                  </span>
                </a>
              </h2>
              <div class="text-center m-b-20">
                <p class="text-muted m-b-0">Nhập địa chỉ email của bạn và chúng tôi sẽ gửi cho bạn một email với hướng
                  dẫn để đặt lại mật khẩu.</p>
              </div>
              <form #form="ngForm" (submit)="submit(form)" autocomplete="on">
                <div class="form-group m-b-20 mt-20 row">
                  <div class="col-12">
                    <app-text-box type="email" title="Nhập email của bạn" name="email" email="true" placeholder="Email"
                      required="true" [(ngModel)]="email">
                      <div invalidmessage="email">
                        <p *invalidType="'required'">Bạn vui lòng nhập email</p>
                        <p *invalidType="'email'">Vui lòng nhập email hợp lệ</p>
                      </div>
                    </app-text-box>
                  </div>
                </div>

                <div class="form-group row text-center m-b-20">
                  <div class="col-12">
                    <button class="btn btn-block btn-custom waves-effect waves-light">Gửi yêu cầu</button>
                  </div>
                </div>

                <div class="row m-t-50">
                  <div class="col-sm-12 text-center">
                    <p class="text-muted">Quay về trang <a routerLink="/login" class="text-dark m-l-5"><b>Đăng Nhập</b></a></p>
                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>

      <div class="m-t-40 text-center">
        <p class="account-copyright">{{background.footerText}}</p>
      </div>
    </div>

  </div>

</div>

<ng-template #customLoadingTemplate>
  <div class="custom-class">
  </div>
</ng-template>
<ngx-loading [show]="elementLoading" [template]="customLoadingTemplate">
</ngx-loading>
