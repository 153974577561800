<div class="row">
  <div class="col-12 grid-margin  stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="fixed-img-name">
        <div *ngIf="salary.userModel">

        <div class="header-salary">
          <span class="mb-2">
            Bảng lương tháng {{date | date: 'MM/yyyy'}}
          </span>
          <div class="text-dark font-18 font-600 mb-2">
            <button type="button" class="btn btn-light" (click)="updateMonth('previous')">
              <i class="mdi mdi-arrow-left-bold"></i>
            </button>
            <button type="button" class="btn btn-light mr-1" (click)="updateMonth('next')">
              <i class="mdi mdi-arrow-right-bold"></i>
            </button>
            <button type="button" class="btn btn-custom" (click)="updateMonth('today')">
              Hôm nay
            </button>
            <button *ngIf="isAdmin" type="button" class="{{(salary.isApprove|| salary.isEmptySalary)?'approve btn btn-light ':'btn btn-light'}}"
            (click)="lockSalary()">
          Khóa sổ
        </button>
          <button *ngIf="!salary.isEmptySalary" type="button" class="btn btn-light" (click)="exportSalaryForCurrentUser()">Trích xuất</button>
          </div>
        </div>
        </div>

        </div>

      <div  class="btn-group text-right fixed-btn-group" role="group" aria-label="salary button">
        <span class="badge badge-custom">
          <app-shared-image [srcImg]="salary.userModel.avatar | image"
                            [classImg]="'img-cover img-xs rounded-circle mt-2'"
                            [altImg]="'image'"
                            [height]="55"
                            [width]="55">
          </app-shared-image>
          <span class="salaryNameDisplay mt-3 ml-3">
            <p class="text-dark font-15 text-uppercase text-bold">{{salary.userModel.fullName}}
              </p>
              <p class="text-dark font-13 text-uppercase">Thành viên từ {{salary.userModel.dateJoinCompany | date: 'MM/yyyy'}}</p>
          </span>
        </span>
      </div>
      <div class="table-layout-adm">
        <table *ngIf="!salary.isEmptySalary" class="table table-striped table-bordered">
          <tbody>
            <tr>
              <th colspan="2">Lương cơ bản</th>
              <td>{{salary.basicSalary | appNumber}}</td>
            </tr>

            <tr>
              <th rowspan="4">Phụ cấp không đóng BH</th>
              <th>Ăn trưa</th>
              <td>{{salary.lunchMoney | appNumber}}</td>
            </tr>

            <tr>
              <th>Điện thoại</th>
              <td>{{salary.telephoneFee | appNumber}}</td>
            </tr>

            <tr>
              <th>Xăng xe</th>
              <td>{{salary.petrolMoney | appNumber}}</td>
            </tr>

            <tr>
              <th>Nhà ở</th>
              <td>{{salary.housingSupport | appNumber}}</td>
            </tr>

            <tr>
              <th colspan="2">Hiệu suất làm việc</th>
              <td>{{salary.salaryPerformance | appNumber}}</td>
            </tr>

            <tr>
              <th colspan="2">Tiền thưởng lễ</th>
              <td>{{salary.holidayBonus | appNumber}}</td>
            </tr>

            <tr>
              <th rowspan="{{this.rowspanOT}}">Chi tiết tăng ca</th>
            </tr>

            <ng-container *ngFor="let overtime of salary.overTimeInfos">              
              <tr>
                <th colspan="2" class="project-name">Trên {{overtime.projectName}} {{!overtime.isIncludePIT ? '(không khấu trừ thuế)' : '' }}</th>
              </tr>

              <tr>
                <th>Số giờ</th>
                <td>{{overtime.totalWorkTimeWithRate | appNumber}}</td>
              </tr>
  
              <tr>
                <th>Số tiền quy đổi </th>
                <td>{{overtime.rateWithCustomer | appNumber}}</td>
              </tr>

              <tr>
                <th>Thành tiền</th>
                <td>{{overtime.salaryOTProject | appNumber}}</td>
              </tr>

            </ng-container>

            <tr>
              <th rowspan="2">Tăng ca khấu trừ thuế</th>
              <th colspan="1">Tổng số giờ đã tăng ca</th>
              <td>{{salary.totalOTHours | appNumber}}</td>
            </tr>

            <tr>
              <th colspan="1">Tổng tiền tăng ca</th>
              <td>{{salary.otSalary | appNumber}}</td>
            </tr>

            <tr class="minus-salary">
              <th class="backgroud-white" rowspan="2">Tăng ca không khấu trừ thuế</th>
              <th colspan="1">Tổng số giờ đã tăng ca</th>
              <td>{{salary.totalOTHourExemptPIT | appNumber}}</td>
            </tr>

            <tr class="minus-salary">
              <th colspan="1">Tổng tiền tăng ca</th>
              <td>{{salary.otSalaryExemptPIT | appNumber}}</td>
            </tr>

            <tr>
              <th colspan="2">Tổng phụ cấp, thưởng</th>
              <td>{{salary.totalAllowance | appNumber}}</td>
            </tr>

            <tr class="minus-salary">
              <th colspan="2">Khấu trừ ngày nghỉ</th>
              <td>{{salary.paidDayOff | appNumber}}</td>
            </tr>

            <tr>
              <th colspan="2">Tổng thu nhập</th>
              <td>{{salary.totalIncome | appNumber}}</td>
            </tr>

            <tr>
              <th colspan="2">Lương tính đóng BHXH, BHYT, BHTN</th>
              <td>{{salary.salaryCalculatedForBHXHnBHYT | appNumber}}</td>
            </tr>
            
            <tr>
              <th rowspan="5">Khoản trích tính vào CP</th>
              <th>BHXH </th>
              <td>{{salary.bhxh | appNumber}}</td>
            </tr>
            <tr>
              <th>BHYT </th>
              <td>{{salary.bhyt | appNumber}}</td>
            </tr>
            <tr>
              <th>BHTN </th>
              <td>{{salary.bhtn | appNumber}}</td>
            </tr>
            <tr>
              <th>KPCĐ</th>
              <td>{{salary.kpcd | appNumber}}</td>
            </tr>
            <tr>
              <th>Cộng </th>
              <td>{{salary.totalCP | appNumber}}</td>
            </tr>

            <tr *ngIf="salary.salaryType == salaryType.Gross">
              <th rowspan="4">Bảo hiểm bắt buộc</th>
              <th>BHXH</th>
              <td>{{salary.bhxhCompulsory | appNumber}}</td>
            </tr>

            <tr *ngIf="salary.salaryType == salaryType.Gross">
              <th>BHYT</th>
              <td>{{salary.bhytCompulsory | appNumber}}</td>
            </tr>

            <tr *ngIf="salary.salaryType == salaryType.Gross">
              <th>BHTN </th>
              <td>{{salary.bhtnCompulsory | appNumber}}</td>
            </tr>

            <tr *ngIf="salary.salaryType == salaryType.Gross">
              <th>Cộng</th>
              <td>{{salary.totalCompulsoryInsurance | appNumber}}</td>
            </tr>

            <tr>
              <th rowspan="9"></th>
              <th>Giảm trừ bản thân</th>
              <td>{{salary.reduceYourself | appNumber}}</td>
            </tr>

            <tr>
              <th>Thu nhập miễn thuế TNCN</th>
              <td>{{salary.net | appNumber}}</td>
            </tr>

            <tr>
              <th>TNCN chưa bao gồm tiền thuê nhà</th>
              <td>{{salary.pitExcludingRent | appNumber}}</td>
            </tr>

            <tr>
              <th>TN làm căn cứ quy đổi(bao gồm tiền thuê nhà)</th>
              <td>{{salary.tnConversionIncludingRent | appNumber}}</td>
            </tr>

            <tr>
              <th>Thu nhập tính thuế</th>
              <td>{{salary.taxableIncome | appNumber}}</td>
            </tr>

            <tr>
              <th>Thuế TNCN</th>
              <td>{{salary.pit | appNumber}}</td>
            </tr>


            <tr class="minus-salary">
              <th>Thuế TNCN khấu trừ từ tăng ca</th>
              <td>{{salary.pitByEmployee | appNumber}}</td>
            </tr>

            <tr>
              <th>Tổng thu nhập chịu thuế</th>
              <td>{{salary.totalTaxableIncome | appNumber}}</td>
            </tr>

            <tr class="minus-salary">
              <th>Tạm ứng</th>
              <td>{{salary.cashAdvance | appNumber}}</td>
            </tr>

            <tr *ngIf="salary.salaryType == salaryType.Gross">
              <th colspan="2">Tổng cộng</th>
              <td>{{salary.totalTax | appNumber}}</td>
            </tr>

            <tr>
              <th colspan="2">Thực lĩnh khấu trừ thuế</th>
              <td>{{salary.realSalary | appNumber}}</td>
            </tr>
            
            <tr>
              <th colspan="2">Tổng thực lĩnh</th>
              <td>{{salary.totalRealSalary | appNumber}}</td>
            </tr>
          </tbody>
        </table>
        <h3 *ngIf="salary.isEmptySalary" class="text-dark font-16">Không có dữ liệu bảng lương của tháng này</h3>
      </div>
      </div>

    </div>
  </div>
</div>
