import { DropdownModel } from './dropdown.model';
import { DatetimeUtils } from '../utils/dateutil';
import { SpecialDayType, DayOffOption, HolidayType } from './enums';
import { UserNameModel } from './overtime.model';

export class Leaving {
  id: number;
  timestamp: number;
  datefrom: number;
  dateto: number;
  daysoff: number;
  reason: string;
}

export class DayOffForView {
  id: number;
  dayoffOption: any;
  reason: string;
  isUrgent: boolean;
  isApprove: boolean;
  dateTime: string;
  option: number;
  dayOffStatus: number;
  user: UserNameModel;
  userId: number;
  type: number;
  holidayType: number;
  rate: number;
  hasSentDeletionRequest: boolean;
  canSentDeletionRequest: boolean;
}

export class DayOffParent {
  title: string;
  isurgent: boolean;
  dayoff: Date;
  option: number;
  holidayType: DropdownModel;
  specialdayModels: Holiday[];
}

export class DayOffChildren {
  constructor(date: Date, cssClass: string = "", titleDay: string = "", type: number = 1, holidayType: number = 1, option: number = 1) {
    this.date = date;
    this.cssClass = cssClass;
    this.datetime = DatetimeUtils.toShortDateTimeFormat(date.toString());
    this.titleDay = titleDay;
    this.type = type;
    this.holidayType = holidayType;
    this.option = option;

    //TODO check/return should use Enum instead.
    if (this.cssClass.includes("fullday")) {
      this.option = 0;
    }
    else if (this.cssClass.includes("morning")) {
      this.option = 1;
    }
    else if (this.cssClass.includes("afternoon")) {
      this.option = 2;
    }
    else {
      this.option = -1; //BUG

    }
  }

  titleDay: string = "";
  datetime: string = "";
  type: number = SpecialDayType.DayOff;
  option: number = DayOffOption.FullDay;
  holidayType: number = HolidayType.Certain;
  date: Date; // just for UI
  cssClass: string; // just for UI
}

export class RateOvertimeModels {
  rateOvertimeModels: RateOvertimeModel[];
}

export class RateOvertimeModel {
  rate: number = 0;
  datetime: string = "";
}
export class RateOvertimeApiModel {
  id: number = 0;
  dateTime: Date;
  rate: number = 0;
}

export interface HolidayData {
  date: Date;
  titleDay: string;
  holidayType: number;
  holidayTypeOption: DropdownModel;
  option: number;
  isMakeup: boolean;
  datetime: string;
}

export class Holiday {
  constructor(holidayData: HolidayData){
    this.datetime = DatetimeUtils.toShortDateTimeFormat(holidayData.date.toString());
    this.titleDay = holidayData.titleDay;
    this.date = holidayData.date;
    this.type = holidayData.isMakeup ? SpecialDayType.Makeup : SpecialDayType.Holiday;
    this.holidayType = holidayData.holidayTypeOption? +holidayData.holidayTypeOption.value : 0;
    this.option = holidayData.option;
  }
  titleDay: string;
  datetime: string;
  type: number = SpecialDayType.DayOff;
  option: number = DayOffOption.FullDay;
  holidayType: number;
  date: Date;
};

export interface DayOffToUpdate {
  dayoffIds: number[];
  isApprove: boolean;
}

export interface DayOffToDelete {
  dayoffIds: number[];
  isApprove: boolean;
}

export class DayOffModel
{
    dateTime: Date;
    reason: string;
    option: DayOffOption
    userName: string;
    userId: number;
    userImage: string;
    dayOffId: number;
}

export class UpdateDayOffModel {
  dayoffIds: number[] = [];
  isApprove: boolean;
}

export class SpecialDayModel {
  titleDay: string;
  dateTime: string;
  type: SpecialDayType;
  option: DayOffOption;
  holidayType: HolidayType;
  specialDayId: number;
}
export class EventModel {
  id: number;
  option: number;
  isApprove: boolean;
  dayOffStatus: number;
  isUrgent: boolean;
  userId: number;
  user:UserNameModel;
  type:number;
  holidayType:number;
  isMakeup: boolean;
  date: string;
}
