import { Component, OnInit } from '@angular/core';
import { ChangePasscodeService } from 'src/app/services/changepasscode.service';
import { Router } from '@angular/router';
import { ChangePasscode } from 'src/app/models/changepasscode.model';
import { NgForm } from '@angular/forms';
import { SettingService } from '../../services/setting.service';
import { NotificationService } from '../../services/notify.service';
import { Background } from 'src/app/models/background.model';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  elementLoading: boolean;
  model = new ChangePasscode();  
  background = new Background();
    constructor(
        private changePasscodeService: ChangePasscodeService,
        private router: Router,
        private notifyService: NotificationService,
        private settingService: SettingService) { }
  ngOnInit() {
    this.elementLoading = false;
          this.settingService.getBackground()
          .subscribe(data => {
              if (data) {
                this.background = data;
                if(!this.background.logo)
                  this.background.logo = "'assets/images/logo.png'";
              }
          });
  }

  changePassword(myForm: NgForm) {
    if (myForm.invalid) {
      return;
    }
    this.elementLoading = true;
    this.changePasscodeService.changePasscode(this.model)
        .subscribe(data => {
            this.notifyService.success("Bạn đã đổi mật khẩu thành công !!!");
        setTimeout(() => {
          this.router.navigate(['login']);
        }, 2000) // navigating to homepage after 2 seconds
        },
            error => {
                this.model = new ChangePasscode();
                this.elementLoading = false;
            });
  }
  
}
