<div class="account-pages">
  <!-- Begin page -->
  <div class="accountbg"
    [ngStyle]="{'background-image': 'url(' + background.backgroundImage + ')', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-position': 'center'}">

    <div class="wrapper-page account-page-full">

      <div class="card">
        <div class="card-block">

          <div class="account-box">

            <div class="card-box p-5">
              <h2 class="text-uppercase text-center pb-4">
                <a href="" class="text-success">
                  <span>
                    <app-shared-image [srcImg]="background.logo" altImg="Logon logo" [height]="72"></app-shared-image>
                  </span>
                </a>
              </h2>

              <div class="account-content text-center">
                <app-shared-image [srcImg]="'/assets/images/email.svg'" altImg="Logon logo" [height]="100"></app-shared-image>

                <p class="text-muted font-14 mt-2">
                  Một email đã được gửi đến <b>{{email}}</b>.
                  Bạn vui lòng kiểm tra email và chọn đường dẫn đã được đính kèm để đặt lại mật khẩu của bạn nhé.
                </p>

                <a routerLink="/login" class="btn btn-md btn-block btn-custom waves-effect waves-light mt-3">Quay về
                  trang đăng nhập</a>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="m-t-40 text-center">
        <p class="account-copyright">{{background.footerText}}</p>
      </div>

    </div>

  </div>

</div>

<ng-template #customLoadingTemplate>
  <div class="custom-class">
  </div>
</ng-template>
<ngx-loading [show]="elementLoading" [template]="customLoadingTemplate">
</ngx-loading>