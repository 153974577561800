import { Component, OnInit } from '@angular/core';
import { SettingService } from '../../services/setting.service';
import { Background } from 'src/app/models/background.model';
import { DEFAULT_IMAGE, LOGO_IMAGE } from 'src/app/utils';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {
  background = new Background();
  logoImage: string = DEFAULT_IMAGE;
    constructor(private settingService: SettingService) { }

    ngOnInit() {
        this.settingService.getBackground()
            .subscribe(data => {
                if (data) {
                    this.background = data;
                    this.logoImage = (data.logo && data.logo !== "") ? data.logo : LOGO_IMAGE;
                }
            })
  }

}
