
export class SettingModel {
  curencyExchange: number;
  dateExpireToken: number;
  uploadedFile: File;
  uploadedLogo: File;
  backgroundImage: string;
  favIcon: string;
  documentTitle: string;
  logo: string;
  logonLogo: string;
  userName: string;
  password: string;
  port: number;
  timeRemind: number;
  birthdayEmailTemplate: string;
  salaryEmailTemplate: string;
  forgotPasswordEmailTemplate: string;
  remindEmailTemplate: string;
  footer: string;
  celebratorDate: string;
  celebratorEmailTemplate: string;
  smtpHost: string;
  secureConnection: number;
  sendMailFrom: string
}

export class SettingForms {
  uploadLaborContract: File;
  uploadSubContract: File;
  uploadedProbationaryContract: File;
  uploadCollaboratorContract: File;
  uploadSalaryTemplate: File;
  uploadSalaryBankTemplate: File;

}

export class SystemCurrencyTokenModel {
  curencyExchange: number;
  dateExpireToken: number;
}

export class SystemFixedCostModel {
  tradeUnionFees: number;
}

export class SystemEmailRemindModel {
  userName: string;
  password: string;
  port: number;
  timeRemind: number;
  smtpHost: string;
  secureConnection: number;
  sendMailFrom: string
}

export class SystemBirthdayTemplateConfig {
  birthdayEmailTemplate: string;
}

export class SystemSalaryTemplateConfig{
  salaryEmailTemplate: string;
}

export class SystemRemindTemplateConfig{
  remindEmailTemplate: string;
}

export class SystemForgotPasswordTemplateConfig{
  forgotPasswordTemplate: string;
}

export class SystemLogoBackgroundConfig {
  isBackgroundDelete: boolean;
  isLogoDelete: boolean;
  isLogonLogoDelete: boolean;
  isFavIconDelete: boolean;
  uploadedBackgound: File;
  uploadedLogo: File;
  uploadedLogonLogo: File;
  uploadedFavIcon: File;
  documentTitle: string;
  footer: string;
}

export class CelebratorModel {
  celebratorDate: string;

  constructor(init?: Partial<CelebratorModel>) {
		Object.assign(this, init);
	}
}

export class CelebratorTemplateModel {
  celebratorEmailTemplate: string;

  constructor(init?: Partial<CelebratorTemplateModel>) {
		Object.assign(this, init);
	}
}
