<div class="row">
  <div class="col-12 grid-margin">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Danh sách tăng ca tháng {{date | date: 'MM/yyyy'}}</h4>

        <div class="text-center mt-4">
          <div class="row">
            <div class="col-md-6 col-xl-3">
              <div class="card-box card-normal widget-flat border-custom bg-custom text-white">
                <i class="fi-clock"></i>
                <h3 class="m-b-10">{{overTimeInfo.totalOverTime}} giờ</h3>
                <p class="text-uppercase m-b-5 font-13 font-600" title="Tổng số giờ tăng ca trong tháng">Tổng số giờ tăng ca trong tháng</p>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card-box card-normal bg-primary widget-flat border-primary text-white">
                <i class="fi-head"></i>
                <h3 class="m-b-10">{{overTimeInfo.totalUser}}</h3>
                <p class="text-uppercase m-b-5 font-13 font-600" title="Tổng số nhân viên tăng ca trong tháng">Tổng số nhân viên tăng ca trong tháng</p>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card-box card-normal widget-flat border-success bg-success text-white">
                <i class="fi-bar-graph"></i>
                <h3 class="m-b-10">{{overTimeInfo.maxOverTime}} giờ</h3>
                <p class="text-uppercase m-b-5 font-13 font-600" title="Người tăng ca nhiều nhất : {{overTimeInfo.userOverTime.userName}}">Người tăng ca nhiều nhất : {{overTimeInfo.userOverTime.userName}}
                </p>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card-box card-normal bg-danger widget-flat border-danger text-white">
                <i class="fi-paper"></i>
                <h3 class="m-b-10" title="{{overTimeInfo.totalPaymentOverTime | appNumber}} VNĐ">{{overTimeInfo.totalPaymentOverTime | appNumber}} VNĐ</h3>
                <p class="text-uppercase m-b-5 font-13 font-600"  title="Tổng số tiền tăng ca trong tháng" >Tổng số tiền tăng ca trong tháng</p>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end mb-3">
          <span class="d-flex align-items-center mr-2">
            Tìm: &nbsp;
            <app-text-box type="text"
                          [placeholder]="'nội dung..'"
                          [(ngModel)]="descriptionSearch"
                          (ngModelChange)="searchByDescription()">
            </app-text-box>
          </span>
          <span class="d-flex align-items-center mr-2">
            <select class="form-control form-control" id="phase-select"
                    [(ngModel)]="selectedProjectId"
                    (ngModelChange)="filterOvertimeList()">
              <option [ngValue]="0">Tất cả dự án</option>
              <option *ngFor="let p of projects" [ngValue]="p.id">
                {{ p.projectName }}
              </option>
            </select>
          </span>
          <nz-date-picker
            class="mr-2"
            nzMode="month"
            [nzFormat]="'MM/yyyy'"
            [(ngModel)]="selectedMonth"
            (ngModelChange)="onChangeMonth($event)">
          </nz-date-picker>
          <button type="button" class="btn btn-light ml-2" (click)="updateMonth('previous')">
            <i class="mdi mdi-arrow-left-bold"></i>
          </button>
          <button type="button" class="btn btn-light mr-1" (click)="updateMonth('next')">
            <i class="mdi mdi-arrow-right-bold"></i>
          </button>
          <button type="button" class="btn btn-custom" (click)="updateMonth('today')">
            Hôm nay
          </button>
        </div>

        <div class="table-data">
          <nz-table
            #filterTable
            nzShowPagination
            nzShowSizeChanger
            [nzData]="listOfDisplayOTs"
            nzPageSize="50">
            <thead (nzSortChange)="sort($event)" nzSingleSort>
              <tr>
                <th nzShowSort nzSortKey="user.userId">
                  Mã NV
                </th>
                <th nzShowSort nzSortKey="user.userName">
                  Nhân viên
                </th>
                <th nzShowSort nzSortKey="workTime">
                  Số giờ
                </th>
                <th nzShowSort nzSortKey="description">
                  Nội dung
                </th>
                <th>
                  Dự án
                </th>
                <th nzShowSort nzSortKey="rateType">
                  Chế độ
                </th>
                <th nzShowSort nzSortKey="timeStart">
                  Thời gian bắt đầu
                </th>
                <th nzShowSort nzSortKey="timeFinish">
                  Thời gian kết thúc
                </th>
                <th>
                  Tác vụ
                </th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let emp of filterTable.data">
                <td>
                  {{emp.user.userId}}
                </td>
                <td>
                  <app-shared-image [srcImg]="emp.user.userImage | image"
                                    [classImg]="'rounded-circle img-cover'"
                                    [altImg]="'image'"
                                    [height]="48"
                                    [width]="48">
                  </app-shared-image>
                  <label class="ml-2">{{emp.user.userName}}</label>
                </td>
                <td>
                  {{emp.workTime}}
                </td>
                <td>
                  <label title="{{emp.description}}">{{emp.description}}</label>
                </td>
                <td>
                  <div class="row">
                    <div *ngFor="let proj of emp.projects" style="padding: 5px;">
                      <app-shared-image [srcImg]="proj.projectAvatar | image"
                                        [classImg]="'img-cover rounded-circle img-thumbnail'"
                                        [altImg]="'image'"
                                        [titleImg]="proj.projectName"
                                        [height]="36"
                                        [width]="36">
                      </app-shared-image>
                    </div>
                  </div>
                </td>
                <td>
                  {{emp.rateType}}
                </td>
                <td>
                  {{emp.timeStart | date: 'dd/MM/yyyy, h:mm a'}}
                </td>
                <td>
                  {{emp.timeFinish | date: 'dd/MM/yyyy, h:mm a'}}
                </td>
                <td>
                  <button [attr.data-item-id]="emp.id" id="btn-deleteOT" class="btn btn-danger mr-2" title="Xóa">
                    <i class="mdi mdi-close-octagon"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
    </div>
  </div>
</div>
