import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_ENDPOINT } from '../../app/app.constants';
import { PatternTemplateEnum } from '../models/enums';
import { CelebratorModel, CelebratorTemplateModel } from '../models/setting.model';
import { BaseService } from './common/base.service';
import { ErrorService } from './common/error.service';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class SettingService extends BaseService {

  constructor(protected http: HttpClient,
    protected errorHandler: ErrorService,
    protected globalService: GlobalService) {
    super(http, errorHandler, globalService);

  }

  getSetting() {
    return this.get(API_ENDPOINT.get_setting, true);
  }

  getBackground() {
    return this.get(API_ENDPOINT.get_background, false);
  }

  getDocumentTitle() {
    return this.get(API_ENDPOINT.get_document_title, true);
  }

  updateCurrencyTokenSetting(data: any) {
    return this.postFormData(API_ENDPOINT.update_currency_token_setting, data, true);
  }

  updateCelebratorCompany(data: CelebratorModel) {
    return this.postFormData(API_ENDPOINT.update_celebrator_company, data, true);
  }

  updateCelebratorCompanyTemplateSetting(data: CelebratorTemplateModel) {
    return this.postFormData(API_ENDPOINT.update_celebrator_company_template_setting, data, true);
  }

  UpdateSystemEmailRemindSetting(data: any) {
    return this.postFormData(API_ENDPOINT.update_email_reminder_setting, data, true);
  }

  updateSystemBirthdayTemplateSetting(data: any) {
    return this.postFormData(API_ENDPOINT.update_birthday_template_setting, data, true);
  }

  updateSalaryTemplateSetting(data: any) {
    return this.postFormData(API_ENDPOINT.update_salary_template_setting, data, true);
  }

  updateForgotPasswordTemplateSetting(data: any) {
    return this.postFormData(API_ENDPOINT.update_forgotpassword_template_setting, data, true);
  }

  updateRemindDayoffTemplateSetting(data: any) {
    return this.postFormData(API_ENDPOINT.update_remind_template_setting, data, true);
  }

  UpdateSystemLogoBackgroundSetting(data: any) {
    return this.postFormData(API_ENDPOINT.update_logo_background_setting, data, true);
  }

  UpdateSettingForms(data: any) {
    return this.postFormData(API_ENDPOINT.upload_setting_forms, data, true);
  }

  downloadPatternTemplate(patternEnum: PatternTemplateEnum){
    return this.download(`${API_ENDPOINT.download_pattern_template}?patternEnum=${patternEnum}`, null);
  }
}
