import { Router } from '@angular/router';
import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { AppComponent } from '../../app.component';
import { NavigationService } from '../../services/navigation.service';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Login } from 'src/app/models/login.model';
import { LocalService } from 'src/app/services/common/local.service';
import { LOCAL_STORAGE_VARIABLE } from 'src/app/app.constants';
import { SettingService } from 'src/app/services/setting.service';
import { Background } from 'src/app/models/background.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends NavigationService implements OnInit {
  elementLoading: boolean;
  model = new Login();
  background = new Background();
  constructor(private authService: AuthService,
    private settingService: SettingService,
    router: Router,
    @Inject(forwardRef(() => AppComponent)) private appComm: AppComponent) {
    super(router);

  }

  ngOnInit() {
    this.settingService.getBackground()
      .subscribe(data => {
        if (data) {
          this.background = data;
          if(!this.background.logo)
            this.background.logo = "'assets/images/logo.png'";
        }
      })
    this.elementLoading = false;
  }

  login(form: NgForm) {
    if (form.invalid) {
      return;
    }

    this.elementLoading = true;
    this.authService.login(this.model)
      .subscribe(data => {

        if (data && data.token) {
          LocalService.setAccessToken(data.token);
          LocalService.setLogStatus(true);
          LocalService.setUserAvt(data.avatar);
          LocalService.setUserName(data.fullName);
          LocalService.setUserId(data.userId);
          LocalService.setItem(LOCAL_STORAGE_VARIABLE.is_first_login, data.isFirstLogin);
          if (!data.isFirstLogin)
            this.router.navigate(['']);
          else this.router.navigate(['change-password']);
        }
      },
        () => {
          this.model.passCode = "";
          this.elementLoading = false;
        });

  }

  navigationStarted() {
    this.elementLoading = false;
    this.appComm.navigationStarted();
  }

  navigationEnded() {

  }
  navigationCanceled() {

  }
  navigationRecognized() {

  }


}
