<div class="row">
  <div class="col-12 grid-margin">
    <div class="card">
      <div class="card-body">
        <h4 *ngIf="isAdmin" class="card-title">Nhân viên</h4>
        <h4 *ngIf="!isAdmin || isHR" class="card-title">Đồng nghiệp</h4>

        <div class="text-center mt-4 mb-4">
          <div class="row">
            <div class="col-md-6 col-xl-3">
              <div class="card-box card-normal widget-flat border-custom bg-custom text-white">
                <i class="fi-head"></i>
                <h3 class="m-b-10">{{employeesInformation.totalEmployee}}</h3>
                <p class="text-uppercase m-b-5 font-13 font-600">Tổng Số Nhân Viên</p>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card-box card-normal bg-primary widget-flat border-primary text-white">
                <i class="fi-head"></i>
                <h3 class="m-b-10">{{employeesInformation.employeeOffInDay}}</h3>
                <p class="text-uppercase m-b-5 font-13 font-600">Số Nhân Viên Nghỉ Trong Ngày</p>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card-box card-normal widget-flat border-success bg-success text-white">
                <i class="fi-head"></i>
                <h3 class="m-b-10">{{employeesInformation.employeeOffInWeek}}</h3>
                <p class="text-uppercase m-b-5 font-13 font-600">Số Nhân Viên Nghỉ Trong Tuần</p>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card-box card-normal bg-danger widget-flat border-danger text-white">
                <i class="fi-head"></i>
                <h3 class="m-b-10">{{employeesInformation.employeeOffInMonth}}</h3>
                <p class="text-uppercase m-b-5 font-13 font-600">Số Nhân Viên Nghỉ Trong Tháng</p>
              </div>
            </div>
          </div>
        </div>

        <div class="head-action">
          <div class="col-sm-3" *ngIf="isAdmin || isHR">
            <app-dropdown-list [defaultValue]="employeeFilter" [data]="roleDropdown"
              [(ngModel)]="employeeFilter" (onChangeCallBack)="filterEmployees()">
            </app-dropdown-list>
          </div>

          <button type="button" *ngIf="isAdmin || isHR" class="btn btn-custom btn-fw ml-2 float-right"
            (click)="exportSalaryBank()">
            <i class="mdi mdi-bank"></i>Xuất Bank
          </button>
          <button routerLink='/add-user' type="button" *ngIf="isAdmin || isHR" class="btn btn-success btn-fw ml-2"
            style="float: right">
            <i class="mdi mdi-account-plus"></i>Tạo Mới
          </button>

          <div *ngIf="isAdmin || isHR" class="btn-group dropdown ml-2" style="float: right">
            <button href="javascript: void(0);" class="table-action-btn dropdown-toggle arrow-none btn btn-light btn-md"
              data-toggle="dropdown" aria-expanded="false"><i class="mdi mdi-settings"></i>Tác Vụ</button>
            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" (click)="showModalExportSalary()"><i class="mdi mdi-file-document"></i> Xuất bảng
                lương</a>
              <a class="dropdown-item" (click)="lockEmployees()"><i class="mdi mdi-lock-outline"></i> Tạm khóa</a>
              <a class="dropdown-item" (click)="removeEmployees()"><i class="mdi mdi-account-remove"></i> Xóa nhân
                viên</a>
              <a class="dropdown-item" (click)="sendMessages()"><i class="mdi mdi-message-draw"></i> Gửi thông
                báo</a>
            </div>
          </div>

        </div>

        <div class="table-wrapper">
          <nz-table #rowSelectionTable nzShowPagination nzShowSizeChanger [nzData]="employees" nzPageSize="50">
            <thead>
              <tr>
                <th *ngIf="isAdmin || isHR">
                  <div class="checkbox checkbox-custom checkbox-circle checkbox-single">
                    <input (change)="checkAllChanged($event)" type="checkbox">
                    <label></label>
                  </div>
                </th>
                <th *ngIf="isAdmin || isHR">
                  Mã NV
                </th>
                <th>
                  Họ và tên
                </th>
                <th *ngIf="isAdmin || isHR">
                  Chức danh
                </th>
                <th>
                  Trạng thái
                </th>
                <th *ngIf="isAdmin || isHR" width="300">
                  Tác vụ
                </th>
              </tr>
            </thead>
            <tbody *ngIf="employees">
              <tr *ngFor="let emp of rowSelectionTable.data" (click)="goToProfile($event, emp.id)">
                <td *ngIf="isAdmin || isHR">
                  <div class="checkbox checkbox-custom checkbox-circle checkbox-single">
                    <input type="checkbox" [checked]="emp.isChecked" (change)="emp.isChecked = !emp.isChecked"
                      checked="">
                    <label>
                    </label>
                  </div>
                </td>
                <td *ngIf="isAdmin || isHR" class="tr-view-profile">
                  {{emp.userCode}}
                </td>
                <td class="tr-view-profile">
                  <app-shared-image [srcImg]="emp.avatar | image" [classImg]="'rounded-circle img-cover'"
                    [altImg]="'image'" [height]="48" [width]="48">
                  </app-shared-image>
                  <label class="ml-2">{{emp.fullName}}</label>
                </td>
                <td *ngIf="isAdmin || isHR" class="tr-view-profile" [attr.data-item-link]="'/user-profile/' + emp.id">
                  <div>
                    <ng-container>
                      <div [ngSwitch]="emp.role">
                        <label *ngSwitchCase="1">Quản trị viên</label>
                        <label *ngSwitchCase="2">Quản lý</label>
                        <label *ngSwitchCase="3">Nhân viên</label>
                        <label *ngSwitchCase="4">Thử việc</label>
                        <label *ngSwitchCase="5">Thực tập sinh</label>
                        <label *ngSwitchCase="6">Cộng tác viên</label>
                        <label *ngSwitchCase="7">Freelance</label>
                        <label *ngSwitchCase="8">HR</label>
                      </div>
                    </ng-container>
                  </div>
                </td>

                <td class="tr-view-profile" [attr.data-item-link]="'/user-profile/' + emp.id">
                  <div *ngIf="emp.isDeactive">
                    <label class="badge badge-danger">Tạm Khóa</label>
                  </div>
                  <div *ngIf="!emp.isDeactive">
                    <div *ngIf="emp.isStatusTakeOff; else elseTakeOff">
                      <label class="badge badge-danger">Tạm nghỉ</label>
                    </div>
                    <ng-template #elseTakeOff>
                      <div [ngSwitch]="emp.specialDayType">
                        <label class="badge-success badge" *ngSwitchCase="0">Trực tuyến</label>
                        <label class="badge badge-danger" *ngSwitchCase="1">Nghỉ phép</label>
                        <label class="badge badge-danger" *ngSwitchCase="2">Nghỉ lễ</label>
                        <label class="badge badge-danger" *ngSwitchCase="3">Làm bù</label>
                        <label class="badge badge-danger" *ngSwitchCase="4">Nghỉ cuối tuần</label>
                        <label class="badge badge-danger" *ngSwitchCase="5">Làm từ xa</label>
                      </div>
                    </ng-template>
                  </div>
                </td>

                <td *ngIf="isAdmin || isHR">
                  <button *ngIf="!emp.isDeactive" (click)="goToSalary(emp.id)" type="button" title="Bảng Lương"
                    class="btn btn-dark mr-2">
                    <i class="mdi mdi-cash-usd"></i>
                  </button>
                  <button *ngIf="!emp.isDeactive && emp.isLockedSalary" type="button" title="Khóa sổ"
                    class="btn-locked-salary btn btn-light mr-2">
                    <i class="mdi mdi-file-lock"></i>
                  </button>
                  <button *ngIf="!emp.isDeactive && !emp.isLockedSalary" type="button" title="Khóa sổ"
                    class="btn btn-custom mr-2" (click)="lockSalary(emp)">
                    <i class="mdi mdi-file-lock"></i>
                  </button>
                  <div class="btn-group dropdown mr-2" *ngIf="!emp.isDeactive">
                    <a href="javascript: void(0);"
                      class="table-action-btn dropdown-toggle arrow-none btn btn-light btn-md" data-toggle="dropdown"
                      aria-expanded="false"><i class="mdi mdi-settings"></i></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a class="dropdown-item" (click)="unlockLogin(emp.id)">
                        <i class="mdi mdi-lock-open-outline mr-2 text-muted font-18 vertical-middle"></i>
                        Mở khóa đăng nhập
                      </a>
                      <a class="dropdown-item" (click)="resetPasscode(emp.id)">
                        <i class="mdi mdi-restart mr-2 text-muted font-18 vertical-middle"></i>
                        Đặt lại mật khẩu
                      </a>
                      <a class="dropdown-item" (click)="exportSalary(emp.id,emp.fullName)">
                        <i class="mdi mdi-file-export mr-2 text-muted font-18 vertical-middle"></i>
                        Xuất bảng lương
                      </a>
                      <a class="dropdown-item exportContract" (click)="exportContract(emp.id, emp.fullName)">
                        <i class="mdi mdi-file-word mr-2 text-muted font-18 vertical-middle"></i>
                        Hợp đồng làm việc
                      </a>
                      <a class="dropdown-item exportSubContract" (click)="exportSubContract(emp.id)">
                        <i class="mdi mdi-file-word mr-2 text-muted font-18 vertical-middle"></i>
                        Phụ lục hợp đồng
                      </a>
                      <a class="dropdown-item exportSubContract" (click)="renewContract(emp.id)">
                        <i class="mdi mdi-file-word mr-2 text-muted font-18 vertical-middle"></i>
                        Làm mới hợp đồng
                      </a>
                      <a class="dropdown-item lock" (click)="recalculateDayOffs(emp.id)">
                        <i class="mdi mdi-reload mr-2 text-muted font-18 vertical-middle"></i>
                        Tính lại ngày nghỉ
                      </a>
                      <a class="dropdown-item lock" (click)="lock(emp.id)">
                        <i class="mdi mdi-lock-outline mr-2 text-muted font-18 vertical-middle"></i>
                        Khóa
                      </a>
                      <a class="dropdown-item remove" (click)="remove(emp.id)">
                        <i class="mdi mdi-account-remove mr-2 text-muted font-18 vertical-middle"></i>
                        Xóa
                      </a>
                      <a class="dropdown-item sendMessage" (click)="sendMessage(emp.id)">
                        <i class="mdi mdi-message-draw mr-2 text-muted font-18 vertical-middle"></i>
                        Thông báo
                      </a>
                    </div>
                  </div>

                  <div class="btn-group dropdown" *ngIf="emp.isDeactive">
                    <a href="javascript: void(0);"
                      class="table-action-btn dropdown-toggle arrow-none btn btn-primary btn-md" data-toggle="dropdown"
                      aria-expanded="false"><i class="mdi mdi-settings"></i></a>
                    <div class="dropdown-menu" [attr.data-item-id]="emp.id" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item unlock" (click)="unlock(emp.id)"><i class="mdi mdi-lock-open-outline"></i>
                        Mở
                        khóa</a>
                      <a class="dropdown-item remove" (click)="remove(emp.id)"><i class="mdi mdi-account-remove"></i>
                        Xóa</a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>

      </div>
    </div>
  </div>
</div>


<ng-template #modalExportSalary let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Xuất bảng lương</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModalExportSalary()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #form="ngForm" (ngSubmit)="exportSalaries()" autocomplete="off">
    <div class="modal-body">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="textbox-title" >Chọn thời gian:</label>
            <nz-date-picker nzMode="month" style="width: 100%" [nzDisabledDate]="disabledDate" name="selectedMonth" [(ngModel)]="selectedMonth" ></nz-date-picker>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="modal-footer">
    <button class="btn btn-custom save-event waves-effect waves-light" (click)="exportSalaries()" type="button" >Xác nhận</button>
    <button type="button" class="btn btn-light waves-effect" data-dismiss="modal" (click)="closeModalExportSalary()">Đóng</button>
  </div>
</ng-template>
