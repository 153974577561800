import { IndividualConfig } from 'ngx-toastr';
import { MenuItems } from 'src/app/models/enums';
import { environment } from '../environments/environment';
import { ServerErrorModel } from './models/hardcode.model';

export const API_HOST = environment.apiHost;
export const API_HOST_PRIVATE = environment.apiHost;
export const API_URL_PREFIX = environment.apiPrefix;

export const LOCAL_STORAGE_VARIABLE = {
    is_admin: 'is_admin',
    access_token: 'access_token',
    is_logged_in: 'is_logged_in',
    user_avt: 'user_avatar',
    user_name: 'user_name',
    user_id: 'user_id',
    is_first_login: 'is_first_login'
};

export const LOGIN_STATUS = {
    logged_in: 'true'
};

export const DEFAULT_SETTINGS = {
    time_zone: 'SE Asia Standard Time',
    time_zone_linux: 'Asia/Ho_Chi_Minh'
};

export const REQUEST_TIMEOUT = 30000;

export const FORMAT_DATE_CONSTANT = {
    short_date_time_format: 'dd/MM/yyyy HH:mm:ss',
    short_date_format: 'dd/MM/yyyy',
    short_date_standard: 'yyyy-MM-dd',
    short_date_time_standard: 'yyyy-MM-dd HH:mm:ss',
    date_format_mm_dd_yyyy: 'MM/dd/yyyy',
    date_format_mm_dd_yyyy_HH_mm_ss: 'MM/dd/yyyy HH:mm:ss',
    date_time_format_twelve_hours: 'dd/MM/yyyy h:mm a',
}

export const MOMENT_FORMAT = {
  server_time: 'HH:mm:ss',
  server_date: 'DD/MM/YYYY',
  client_time: 'HH:mm'
}

export const API_ENDPOINT = {
    update_dayoff: '/Home/UpdateDayOff',
    handle_day_off_deletion_request: "/Home/RemoveDayOff",
    update_workremote: '/Home/UpdateWorkRemoteDay',
    get_user_list: '/User/GetAllUsers',
    search_user: '/User/GetAllUsers',
    get_new_userId: '/User/GetNewUserId',
    submit_ot: '/Home/LogOverTime',
    get_ot_list: '/Home/GetUserOverTime',
    get_user_over_time: '/Home/GetUserOverTime',
    get_over_time_info: '/Home/GetOverTimeInfomation',
    submit_daysoff: '/Home/DayOff',
    submit_workremote_day: '/Home/AddWorkRemote',
    get_workremote_day: '/Home/GetWorkRemoteDays',
    delete_dayoff: '/Home/DeleteSpecialDay',
    submit_holiday: '/Home/AddHolidays',
    get_daysoff: '/Home/GetDayOffs',
    get_daysoff_holiday: '/Home/GetDayOffAndHoliday',
    get_holidays: '/Home/GetHoliDaysAsync',
    get_user_info: '/User/GetUserInfo',
    update_user_id: '/User/UpdateUserIdentify',
    export_contract: '/User/ExportContract',
    export_sub_contract: '/User/ExportSubContract',
    renewContract: '/User/RenewContract',
    add_user: '/User/AddUser',
    get_hardcode: '/Home/GetHardcodes',
    update_background: '/Home/ChangeBackground',
    upload_setting_forms: '/Home/UpdatePatternDocument',
    edit_user: '/User/EditUser',
    get_salary_month: '/Salary/GetSalary',
    get_setting: '/Home/GetSetting',
    get_background: '/Home/GetBackground',
    get_document_title: '/Home/GetDocumentTitle',
    update_currency_token_setting: '/Home/UpdateCurrencyAndTokenConfig',
    update_fixed_cost: '/Home/updateFixedCost',
    update_email_reminder_setting: '/Home/UpdateEmailAndRemindConfig',
    update_birthday_template_setting: '/Home/UpdateBirthdayTemplateConfig',
    update_salary_template_setting: '/Home/UpdateSalaryTemplateConfig',
    update_remind_template_setting: '/Home/UpdateRemindTemplateConfig',
    update_celebrator_company_template_setting: '/Home/UpdateCelebratorCompanyTemplateConfig',
    update_forgotpassword_template_setting: '/Home/UpdateForgotPasswordTemplateConfig',
    update_logo_background_setting: '/Home/UpdateLogoAndBackgroundConfig',
    update_celebrator_company: '/Home/UpdateCelebratorCompanyConfig',
    export_salaries: '/Salary/ExportSalaries',
    export_salaries_bank: '/Salary/ExportSalaryBank',
    deactivate_user: '/User/DeactivateUser',
    remove_user: '/User/RemoveUser',
    recalculate_day_offs: '/User/RecalculateDayOffs',
    forgot_passcode_request: '/Home/ForgotPassword',
    new_passcode_submit: '/Home/ForgotPasswordRequest',
    add_project: '/Project/CreateProject',
    list_project: '/Project/GetProjects',
    get_user_project_role: '/Project/GetUserProjectManagement',
    get_project: '/Project/GetProject',
    edit_project: '/Project/EditProject',
    detail_project: '/Project/DetailProject',
    delete_project: '/Project/RemoveProject',
    delete_ot: '/Home/RemoveOverTime',
    send_message: '/Home/SendMessageToUser',
    activate_account: '/Home/ActivateUser',
    submit_rateovertime: '/Home/AddRateOvertime',
    get_rateovertime: '/Home/GetRateOverTime',
    delete_rateovertime: '/Home/RemoveRateOverTime',
    unlock_login: '/User/UnlockUserLogin',
    reset_password: '/User/ResetPassword',
    get_max_cash_advance: '/Salary/GetMaxCashAdvance',
    get_cash_advance_list: '/Salary/GetCashAdvanceList',
    submit_cash_advance: '/Salary/CashAdvance',
    cancel_request_cash_advance: '/Salary/CancelCashAdvance',
    get_user_birthday: '/User/GetUserBirthday',
    get_daysoff_not_approved: '/User/GetRequestOffNotApprove',
    get_day_off_deletion_request: '/User/GetDayOffDeletionRequest',
    update_cash_advance: '/Salary/UpdateCashAdvance',
    create_reward: '/Reward/CreateOrSendReward',
    get_reward: '/Reward/GetRewardTemplate',
    delete_reward: '/Reward/DeleteRewardTemplate',
    get_reward_by_id: '/Reward/GetRewardTemplateById',
    get_all_employee_information: '/User/GetAllEmployeeInfomation',
    get_user_index: '/Home/UserIndex',
    download_pattern_template: '/User/DownloadPatternTemplate',
    create_meeting: '/Meeting/CreateMeeting',
    edit_meeting: '/Meeting/EditMeeting',
    get_meetings: '/Meeting/GetMeetings',
    delete_meeting: '/Meeting/DeleteMeeting',
    get_user_meeting: '/User/GetAllUserMeeting',
    get_list_file_policy: '/Policy/GetFileManagement',
    get_list_parent_policy: '/Policy/GetListParent',
    add_file_policy: '/Policy/AddFilePolicy',
    get_file_policy_by_id:"/Policy/GetFilePolicyById",
    edit_file_policy:"/Policy/EditFilePolicy",
    delete_file_policy: "/Policy/DeleteFilePolicy",
    add_department: '/Department/CreateDepartment',
    list_department: '/Department/GetDepartments',
    get_department: '/Department/GetDepartment',
    edit_department: '/Department/EditDepartment',
    delete_department: '/Department/RemoveDepartment',
    update_department_logo: '/Department/UpdateDepartmentLogo',
    get_room_by_room_id: '/ScrumPocker/GetRoomByRoomId',
    get_rooms: '/ScrumPocker/GetRooms',
    get_rooms_by_project_id: '/ScrumPocker/GetRoomsByProjectId',
    create_room: '/ScrumPocker/CreateRoom',
    get_estimates: '/ScrumPocker/GetEstimates',
    estimate: '/ScrumPocker/Estimate',
    delete_room: '/ScrumPocker/DeleteRoom',
    get_room_by_id: '/ScrumPocker/GetRoomById',
    delete_estimates: '/ScrumPocker/DeleteEstimates',
    update_description: '/ScrumPocker/UpdateDescription',
    exit_room: '/ScrumPocker/ExitRoom',
    get_joined_rooms_by_projectId: '/ScrumPocker/GetJoinedRoomsByProjectId',
    set_user_take_off: '/User/SetUserTakeOff',
    cancel_take_off_user: '/User/CancelTakeOffUser',
    add_edit_customer: '/Customer/AddEditCustomer',
    list_customer: '/Customer/GetCustomers',
    get_customer: '/Customer/GetCustomer',
    remove_customer: '/Customer/RemoveCustomer',
    get_change_salary: '/User/GetListChangeSalary'
}

export const SERVER_ERROR_MESSAGES: ServerErrorModel[] = [
    {
        Key: "server_error",
        Value: "Lỗi hệ thống!!!"
    },
    {
        Key: "username_already_exist",
        Value: "Tên đăng nhập đã tồn tại!!!"
    },
    {
        Key: "email_or_phone_number_already_exist",
        Value: "Email hoặc số điện thoại đã tồn tại!!!"
    },
    {
        Key: "email_invalid",
        Value: "Email không hợp lệ!!!"
    },
    {
        Key: "list_holiday_is_empty",
        Value: "Ngày nghỉ lễ không hợp lệ!!!"
    },
    {
        Key: "insert_holidays_is_failed",
        Value: "Thêm ngày nghỉ lễ thất bại!!!"
    },
    {
        Key: "insert_rateovertime_is_failed",
        Value: "Thêm tỷ giá tăng ca thất bại!!!"
    },
    {
        Key: "dayoff_is_not_available",
        Value: "Ngày nghỉ phép không tồn tại!!!"
    },
    {
        Key: "update_dayoff_failed",
        Value: "Cập nhật ngày nghỉ phép thất bại!!!"
    },
    {
        Key: "project_name_not_null",
        Value: "Tên dự án không được để trống!!!"
    },
    {
        Key: "can_not_delete_user",
        Value: "Không thể xóa được nhân viên này!!!"
    },
    {
        Key: "can_not_deactivate_user",
        Value: "Không thể khóa nhân viên này!!!"
    },
    {
        Key: "video_large",
        Value: "Dung lượng video không hợp lệ!!!"
    },
    {
        Key: "password_invalid",
        Value: "Mật khẩu không hợp lệ!!!"
    },
    {
        Key: "update_password_failed",
        Value: "Cập nhật mật khẩu thất bại!!!"
    },
    {
        Key: "email_or_phone_number_invalid",
        Value: "Email hoặc số điện thoại không hợp lệ!!!"
    },
    {
        Key: "auth_forbidden_error",
        Value: "Bạn không có đủ quyền!!!"
    },
    {
        Key: "user_not_found",
        Value: "Không tìm thấy nhân viên!!!"
    },
    {
        Key: "change_password_expire",
        Value: "Thời gian đổi mật khẩu đã hết hạn!!!"
    },
    {
        Key: "change_password_done",
        Value: "Đổi mật khẩu thành công!!!"
    },
    {
        Key: "password_has_changed",
        Value: "Mật khẩu đã được thay đổi thành công!!!"
    },
    {
        Key: "finish_time_invalid",
        Value: "Thời gian kết thúc không hợp lệ!!!"
    },
    {
        Key: "list_dayoff_is_empty",
        Value: "Ngày nghỉ không hợp lệ!!!"
    },
    {
        Key: "you_cant_log_ot_for_yesterday",
        Value: "Không thể cập giờ làm thêm cho ngày hôm qua!!!"
    },
    {
        Key: "work_time_invalid",
        Value: "Thời gian làm việc không hợp lệ!!!"
    },
    {
        Key: "project_not_found",
        Value: "Không thể tìm thấy dự án!!!"
    },
    {
        Key: "delete_dayoff_is_failed",
        Value: "Đã xảy ra lỗi khi xóa ngày nghỉ!!!"
    },
    {
        Key: "datetime_invalid",
        Value: "Ngày giờ không hợp lệ!!!"
    },
    {
        Key: "password_incorrect",
        Value: "Mật khẩu không đúng!!!"
    },
    {
        Key: "forgot_password_done",
        Value: "Quên mật khẩu xong!!!"
    },
    {
        Key: "files_not_be_chosen",
        Value: "Chưa có tệp nào được chọn!!"
    },
    {
        Key: "fields_is_empty",
        Value: "Những trường này không thể bỏ trống!!!"
    },
    {
        Key: "field_is_empty",
        Value: "Trường này không thể bỏ trống!!!"
    },
    {
        Key: "start_time_invalid",
        Value: "Thời điểm bắt đầu không hợp lệ!!!"
    },
    {
        Key: "user_is_deactivate",
        Value: "Tài khoản của bạn đang bị ngừng hoạt động!!!"
    },
    {
        Key: "user_is_activated",
        Value: "Tài khoản của bạn đã được kích hoạt!!!"
    },
    {
        Key: "user_is_early_activate",
        Value: "Tài khoản của bạn đã được kích hoạt trước đó!!!"
    },
    {
        Key: "user_has_been_locked",
        Value: "Tài khoản của bạn hiện đang bị khóa!!!"
    },
    {
        Key: "overtime_not_found",
        Value: "Không tìm thấy giờ làm thêm!!!"
    },
    {
        Key: "rate_overtime_not_found",
        Value: "Không tìm thấy tỷ giá tăng ca!!!"
    },
    {
        Key: "cant_remove_ot",
        Value: "Không thể xóa giờ làm thêm!!!"
    },
    {
        Key: "num_dayoff_not_is_valid",
        Value: "Bạn phải chọn thời gian bắt đầu nghỉ phép ít nhất bằng số ngày bạn sẽ nghỉ. Ví dụ nghỉ 2 ngày phải xin nghỉ trước 2 ngày!!!"
    },
    {
        Key: "salary_has_been_the_lock",
        Value: "Lương của nhân viên đã bị khóa!!!"
    },
    {
        Key: "cash_advance_invalid",
        Value: "Số tiền bạn muốn ứng không hợp lệ!!!"
    },
    {
        Key: "update_dayoff_fail",
        Value: "Cập nhật ngày nghỉ không thành công!!!"
    },
    {
        Key: "update_dayoff_success",
        Value: "Cập nhật ngày nghỉ thành công!!!"
    },
    {
        Key: "schedule_time_invalid",
        Value: "Sắp xếp thời gian bất hợp lệ!!!"
    },
    {
        Key: "day_off_not_submit_at_weekend",
        Value: "Không thể xin nghỉ vào ngày cuối tuần!!!"
    },
    {
        Key: "day_off_urgent_just_for_half_day",
        Value: "Chỉ xin nghỉ khẩn cấp cho nữa ngày!!!"
    },
    {
        Key: "reward_not_exist",
        Value: "Khen thưởng không tìm thấy!!!"
    },
    {
        Key: "reward_not_have_content",
        Value: "Khen thưởng không có nội dung!!!"
    },
    {
        Key: "reward_not_have_subject",
        Value: "Khen thưởng không có tiêu đề!!!"
    },
    {
        Key: "can_not_delete_reward",
        Value: "Không thể xóa khen thưởng!!!"
    },
    {
        Key: "reward_have_finish",
        Value: "Khen thưởng đã hoàn tất!!!"
    },
    {
        Key: "reward_value_invalid",
        Value: "Số tiền khen thưởng không hợp lệ!!!"
    },
    {
        Key: "cash_advance_not_found",
        Value: "Không tìm thấy Số tiền đã ứng!!!"
    },
    {
        Key: "cash_advance_has_been_approved",
        Value: "Tiền ứng đã được Chấp nhận, Bạn không thể xóa!!!"
    },
    {
        Key: "file_not_found",
        Value: "Không thể tìm thấy tệp tin!!!"
    },
    {
        Key: "dayoff_is_not_null",
        Value: "Bạn phải chọn ngày để thêm tỷ giá tăng ca!!!"
    },
    {
        Key: "exist_a_dayoff_invalid",
        Value: "Ngày nghỉ không hợp lệ!!!"
    },
    {
        Key: "this_new_password_same_old_password",
        Value: "Mật khẩu mới phải khác mật khẩu cũ!!!"
    },
    {
        Key: "files_not_be_chosen",
        Value: "Không có tệp nào được chọn!!!"
    },
    {
        Key: "meeting_not_found",
        Value: "Không tìm thấy cuộc họp!!!"
    },
    {
        Key: "user_not_permission",
        Value: "Bạn không có đủ quyền!!!"
    },
    {
        Key: "can_not_off_at_holiday",
        Value: "Không thể nghỉ vào ngày lễ!!!"
    },
    {
        Key: "you_can_not_off_at_monday",
        Value: "Không được nghỉ vào thứ hai!!!"
    }
    ,
    {
        Key: "day_off_invalid",
        Value: "Ngày nghỉ không hợp lệ!!!"
    }
    ,
    {
      Key: "can_not_delete_folder_parent",
      Value: "Không thể xóa folder có chứa file"
    }
    ,
    {
      Key: "can_not_delete_folder_or_file",
      Value: "Không thể xóa file hoặc folder đã chọn!!!"
    }
    ,
    {
      Key: "User_Code_already_exist",
      Value: "Mã nhân viên đã tồn tại!!!"
    }
    ,
    {
      Key: "User_Code_not_found",
      Value: "Vui lòng nhập mã nhân viên!!!"
    },
    {
      Key: "num_wfh_not_is_valid",
      Value: "Vui lòng chọn ngày làm việc từ xa trước 1 ngày!!!"
    },
    {
      Key: "list_remote_days_is_empty",
      Value: "Ngày làm việc từ xa không hợp lệ!!!"
    },
    {
      Key: "INSERT_REMOTE_DAYS_IS_FAILED",
      Value: "Không thể thêm ngày làm việc từ xa!!!"
    },
    {
      Key: "USER_NOT_IN_PROJECT",
      Value: "Bạn chưa được thêm vào dự án này!!!"
    },
];

export const ADMIN_CONST = '1';
export const MANAGER_CONST = '2';
export const HR_CONST = '8';

export const ToastrAlertConfig: Partial<IndividualConfig> = {
    timeOut: 2000, //millisecond
    progressBar: true,
    progressAnimation: "decreasing",
    closeButton: true
};

export const HARDCODE_CONST = {
    holidayType: "HolidayType",
    dayOffOption: "DayOffOption",
    projectStatus: "ProjectStatus",
    departmentStatus: "DepartmentStatus",
    overTimeRateType: "OvertimeRateType",
    userRole: "UserRole",
    gender: "Gender",
    salaryType: "SalaryType",
    maritalStatus: "MaritalStatus",
    alertGroups: "AlertGroups",
    currencyType: "CurrencyType",
};

export const APP_ROUTER = {
    room: 'room',
    scrum_pocker: 'scrum-pocker'
}

export const POCKER_ESTIMATE_DATA =
    [{
        name: '0.5 Giờ',
        value: 0.5
    },
    {
        name: '1 Giờ',
        value: 1
    },
    {
        name: '2 Giờ',
        value: 2
    },
    {
        name: '3 Giờ',
        value: 3
    },
    {
        name: '4 giờ',
        value: 4
    },
    {
        name: '5 Giờ',
        value: 5
    },
    {
        name: '6 Giờ',
        value: 6
    },
    {
        name: '7 Giờ',
        value: 7
    },
    {
        name: '8 Giờ',
        value: 8
    },
    {
        name: '9 Giờ',
        value: 9
    },
    {
        name: '10 Giờ',
        value: 10
    },
    {
        name: '12 Giờ',
        value: 12
    }
        ,
    {
        name: '14 Giờ',
        value: 14
    },
    {
        name: '16 Giờ',
        value: 16
    },
    ]

export const LEFT_MENU_USER = [{
    title: 'Trang chủ',
    icon: 'fi-air-play',
    routerLink: '/',
    menuItem: MenuItems.Home
  },
  {
    title: 'Đồng nghiệp',
    icon: 'menu-icon mdi mdi-account-multiple',
    routerLink: '/users',
    menuItem: MenuItems.Users
  },
  {
    title: 'Dự án',
    icon: 'menu-icon  mdi  mdi mdi-note-multiple-outline',
    routerLink: '/list-project',
    menuItem: MenuItems.Project
  },
  {
    title: 'Phòng ban',
    icon: 'menu-icon  mdi  mdi mdi-sitemap',
    routerLink: '/list-department',
    menuItem: MenuItems.Department
  },
  {
    title: 'Hội họp',
    icon: 'menu-icon mdi mdi-message-video',
    routerLink: '/meeting',
    menuItem: MenuItems.Meeting
  },
  {
    title: 'Ngày nghỉ',
    icon: 'menu-icon mdi mdi-calendar-today',
    routerLink: '/leaving-detail',
    menuItem: MenuItems.DayOff
  },
  {
    title: 'Làm việc từ xa',
    icon: 'menu-icon mdi mdi-account-network',
    routerLink: '/work-remote',
    menuItem: MenuItems.WorkRemote
  },
  {
    title: 'Tăng ca',
    icon: 'menu-icon mdi mdi-av-timer',
    routerLink: '/overtime-list',
    menuItem: MenuItems.OverTime
  },
  {
    title: 'Ứng lương',
    icon: 'menu-icon mdi mdi-cash-multiple',
    routerLink: '/cash-advance',
    menuItem: MenuItems.CashAdvance
  },
  {
    title: 'Bảng lương',
    icon: 'menu-icon mdi mdi-file-document-box',
    routerLink: '/salary-detail',
    menuItem: MenuItems.Salary
  }]

export const LEFT_MENU_ADMIN = [{
    title: 'Trang chủ',
    icon: 'fi-air-play',
    routerLink: '/admin-dashboard',
    menuItem: MenuItems.Home
  },
  {
    title: 'Khách hàng',
    icon: 'menu-icon mdi mdi mdi-note-multiple-outline',
    routerLink: '/list-customer',
    menuItem: MenuItems.Customer
  },
  {
    title: 'Dự án',
    icon: 'menu-icon  mdi  mdi mdi-note-multiple-outline',
    routerLink: '/list-project',
    menuItem: MenuItems.Project
  },
  {
    title: 'Phòng ban',
    icon: 'menu-icon  mdi  mdi mdi-sitemap',
    routerLink: '/list-department',
    menuItem: MenuItems.Department
  },
  {
    title: 'Nhân viên',
    icon: 'menu-icon mdi mdi-account-multiple',
    routerLink: '/users',
    menuItem: MenuItems.Users
  },
  {
    title: 'Hội họp',
    icon: 'menu-icon mdi mdi-message-video',
    routerLink: '/meeting',
    menuItem: MenuItems.Meeting
  },
  {
    title: 'Ngày nghỉ',
    icon: 'menu-icon mdi mdi-calendar-today',
    routerLink: '/leaving-detail',
    menuItem: MenuItems.DayOff
  },
  {
    title: 'Làm việc từ xa',
    icon: 'menu-icon mdi mdi-account-network',
    routerLink: '/work-remote',
    menuItem: MenuItems.WorkRemote
  },
  {
    title: 'Làm thêm giờ',
    icon: 'menu-icon mdi mdi-av-timer',
    routerLink: '/overtime-view',
    menuItem: MenuItems.OverTime
  },
  {
    title: 'Tỷ giá tăng ca',
    icon: 'menu-icon mdi mdi-av-timer',
    routerLink: '/rateovertimes',
    menuItem: MenuItems.RateOvertime
  },
  {
    title: 'Khen thưởng',
    icon: 'menu-icon mdi mdi-cash-usd',
    routerLink: '/reward-list',
    menuItem: MenuItems.Reward
  },
  {
    title: 'Cài đặt',
    icon: 'fi-cog',
    routerLink: '/setting',
    menuItem: MenuItems.Setting
}]
