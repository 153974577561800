<div class="account-pages">
  <!-- Begin page -->
  <div class="accountbg"
    [ngStyle]="{'background-image': 'url(' + background.backgroundImage + ')', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-position': 'center'}">


    <div class="wrapper-page account-page-full">
      <div class="card">
        <div class="card-block">

          <div class="account-box">

            <div class="card-box p-5">
              <h2 class="text-uppercase text-center pb-4">
                <a routerLink="/" class="text-success">
                  <span>
                    <app-shared-image [srcImg]="logoImage" [altImg]="'image'" [height]="72">
                    </app-shared-image>
                  </span>
                </a>
              </h2>

              <div class="form-group m-b-20 row">
                <div class="col-12">
                  <h1 class="text-error text-center">404</h1>
                  <h4 class="text-uppercase text-danger text-center mt-3">Xin lỗi!</h4>
                <p class="text-muted mt-3" [ngStyle]="{'text-align': 'center'}">
                    Chúng tôi không thể tìm thấy trang web mà bạn đang tìm kiếm.
                  </p>
                </div>
              </div>

              <div class="form-group row text-center m-b-20">
                <div class="col-12">
                  <button class="btn btn-block btn-custom waves-effect waves-light" routerLink="/">Quay về trang
                    chủ</button>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <div class="m-t-40 text-center">
        <p class="account-copyright">{{background.footerText}}</p>
      </div>
    </div>

  </div>
</div>