import { Injectable } from '@angular/core';
import { BaseService } from './common/base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './common/error.service';
import { GlobalService } from './global.service';
import { UserShortInfo, UserInfo, UserIdentity } from '../models/userinfo.model';
import { Observable } from 'rxjs';
import { API_ENDPOINT } from '../../app/app.constants';
import { SendMessageModel } from '../models/sendmessage.model';
import { UserIdsModel, UserIdModel, SalaryChangeModel } from '../models/user';
import { DatetimeUtils } from 'src/app/utils/dateutil';
import { OverTimeModel } from '../models/overtime.model';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  constructor(protected http: HttpClient,
    protected errorHandler: ErrorService,
    protected globalService: GlobalService) {
    super(http, errorHandler, globalService);

  }

  getUserProfile(userId: number = 0): Observable<any> {
    return this.get(`${API_ENDPOINT.get_user_info}?tempUserId=${userId}`, null, true);
  }

  getUsers(isLoadInProject: boolean = false): Observable<UserShortInfo[]> {
    return this.get(`${API_ENDPOINT.get_user_list}?isLoadInProject=${isLoadInProject}`, null, true);
  }

  getNewUserId(): Observable<any> {
    return this.get(`${API_ENDPOINT.get_new_userId}`, null, true);
  }

  getUsersMeeing(): Observable<UserShortInfo[]> {
    return this.get(API_ENDPOINT.get_user_meeting, { includeAdmin: true }, true);
  }

  searchUsers(term: string) {
    return this.get(`${API_ENDPOINT.search_user}?term=${term}`, null, true);
  }

  getAllEmployeeInformation() {
    return this.get(API_ENDPOINT.get_all_employee_information, null, true);
  }

  getOTList(userId: number, dateTime: string): Observable<OverTimeModel[]> {
    return this.get(`${API_ENDPOINT.get_ot_list}?dateTime=${dateTime}&userID=${userId}`, null, true);
  }

  getChangeSalaryList(userId: number): Observable<SalaryChangeModel[]> {
    return this.get(`${API_ENDPOINT.get_change_salary}?userID=${userId}`, null, true);
  }

  getUserOverTimes(dateTime: string, userId: number, projectId: number): Observable<OverTimeModel[]> {
    return this.get(`${API_ENDPOINT.get_user_over_time}?dateTime=${dateTime}&userID=${userId}&projectId=${projectId}`, null, true);
  }

  getOvertimeInfo(dateTime: string): Observable<any> {
    return this.get(`${API_ENDPOINT.get_over_time_info}?dateTime=${dateTime}`, null, true);
  }

  submitOtLog(model: OverTimeModel): Observable<number> {
    return this.post(API_ENDPOINT.submit_ot, model, true);
  }

  addUser(data: UserInfo) {
    return this.postFormData(API_ENDPOINT.add_user, data, true);
  }

  editUser(data: UserInfo) {
    return this.postFormData(API_ENDPOINT.edit_user, data, true);
  }

  updateUserIdentity(data: UserIdentity) {
    return this.postFormData(API_ENDPOINT.update_user_id, data, true);
  }

  deactivateUser(model: UserIdsModel) {
    return this.post(API_ENDPOINT.deactivate_user, model, true);
  }

  removeUser(model: UserIdsModel) {
    return this.post(API_ENDPOINT.remove_user, model, true);
  }

  unlockLogin(model: UserIdModel) {
    return this.post(API_ENDPOINT.unlock_login, model, true);
  }

  resetPasscode(model: UserIdModel) {
    return this.post(API_ENDPOINT.reset_password, model, true);
  }

  deleteOT(id: number) {
    return this.post(`${API_ENDPOINT.delete_ot}?id=${id}`);
  }

  exportSalary(ids: number[] = [], date: Date) {
    this.download(API_ENDPOINT.export_salaries, { ids: ids, dateTime: DatetimeUtils.toShortDateTimeFormat(date.toString())});
  }

  exportSalaryBank(ids: number[] = []) {
    this.download(API_ENDPOINT.export_salaries_bank, { ids: ids });
  }

  exportSalaryWithUserName(ids: number[] = [], userName: string, date: Date) {
    this.download(API_ENDPOINT.export_salaries, { ids: ids, dateTime: DatetimeUtils.toShortDateTimeFormat(date.toString()), userName });
  }

  sendMessage(messageModel: SendMessageModel) {
    return this.postFormData(API_ENDPOINT.send_message, messageModel, true);
  }

  GetTimeZones() {
    const url = `/Home/GetTimeZoneInfos`
    return this.get(url, undefined, false, true);
  }

  activate(token: string) {
    return this.post(API_ENDPOINT.activate_account, { token: token });
  }


  //admin get contract user
  exportContractUser(userId: number, fullName?: string) {
    this.download(`${API_ENDPOINT.export_contract}/${userId}`);
  }

  // current user get contract
  exportContract() {
    this.download(API_ENDPOINT.export_contract);
  }

  exportSubContract(userId: number) {
    this.download(API_ENDPOINT.export_sub_contract, { id: userId });
  }

  renewContract(model: UserIdModel) {
    return this.post(API_ENDPOINT.renewContract, model, true);
  }

  getUserByBirthdayMonth() {
    return this.get(API_ENDPOINT.get_user_birthday, null, false);
  }

  getUserIndex(model: UserIdModel) {
    return this.post(API_ENDPOINT.get_user_index, model, true);
  }

  setUserTakeOff(data: UserInfo) {
    return this.postFormData(API_ENDPOINT.set_user_take_off, data, true);
  }

  cancelTakeOffUser(userId: number) {
    return this.post(`${API_ENDPOINT.cancel_take_off_user}?userId=${userId}`);
  }

  recalculateDayOffs(userId: number) {
    return this.post(`${API_ENDPOINT.recalculate_day_offs}?userId=${userId}`);
  }
}
