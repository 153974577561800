import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { UserProfileComponent } from './components/user/user-profile/user-profile.component';
import { LoginAccessGuard } from './guards/login.guard';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AddUserComponent } from './components/user/add-user/add-user.component';
import { SubmitOvertimeComponent } from './components/overtime/overtime-submit/submit-overtime.component';
import { UsersComponent } from './components/user/users/users.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { AdminAccessGuard } from './guards/admin.guard.';
import { HomepageComponent } from './components/homepage/homepage.component';
import { LeavingDetailComponent } from './components/leaving-detail/leaving-detail.component';
import { SalaryDetailComponent } from './components/salary-detail/salary-detail.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { HttpClientModule } from '@angular/common/http';
import { ErrorService } from './services/common/error.service';
import { GlobalService } from './services/global.service';
import { TextBoxComponent } from './controls/text-box/text-box.component';
import { SearchBoxComponent } from './controls/search-box/search-box.component';

import { UserProfileUpdateComponent } from 'src/app/components/user/user-profile-update/user-profile-update.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ToastrModule } from 'ngx-toastr';
import { FullCalendarModule } from '@fullcalendar/angular';

import { DropdownListComponent } from './controls/dropdown-list/dropdown-list.component';
import { InvalidTypeDirective } from './directives/invalid-type.directive';
import { InvalidmessageDirective } from './directives/invalid-message.directive';
import { PasswordValidator } from './validations/password.derective';
import { MinValidator } from './validations/min.directive';
import { ImagePipe } from './pipes/image.pipe';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap';
import { ConfirmDialogComponent } from './components/dialog/confirm-dialog/confirm-dialog.component';
import { MessageDialogComponent } from './components/dialog/message-dialog/message-dialog.component';
import { InputDialogComponent } from './components/dialog/input-dialog/input-dialog.component';
import { SelectImageComponent } from './controls/select-image/select-image.component';
import { MinDateValidator } from './validations/mindate.derective';
import { AppNumberPipe } from './pipes/number.pipe';
import { AddProjectComponent } from './components/project/add-project/add-project.component';
import { ResetPasswordComponent } from './components/reset-passcode/reset-passcode.component';
import { ListProjectComponent } from './components/project/list-project/list-project.component';
import { OvertimeListComponent } from './components/overtime/overtime-list/overtime-list.component';
import { ActivateUserComponent } from './components/user/activate-user/activate-user.component';
import { MaxValidator } from './validations/max.directive';
import { RealtimeClientService } from './services/realtime.client.service';
import { RateOverTimesComponent } from './components/rateovertimes/rateovertimes.component';
import { PhoneValidator } from './validations/phone.derective';
import { ConfirmMailComponent } from './components/confirm-mail/confirm-mail.component';
import { CashAdvanceComponent } from './components/cash-advance/cash-advance.component';
import { ViewProjectComponent } from './components/project/view-project/view-project.component';
import { NotifyListComponent } from './components/notify-list/notify-list.component';
import { EqualValidator } from './validations/equalpassword.derective';
import { SettingComponent } from './components/setting/setting.component';
import { OvertimeViewComponent } from './components/overtime/overtime-view/overtime-view.component';
import { AppDatePickerComponent } from './controls/date-picker/date-picker.component';
import { RewardListComponent } from './components/rewards/reward-list/reward-list.component';
import { MaintainanceComponent } from './components/maintainance/maintainance.component';
import { HomepageAdminComponent } from './components/homepage-admin/homepage-admin.component';
import { RewardComposeComponent } from './components/rewards/reward-compose/reward-compose.component';
import { RewardViewComponent } from './components/rewards/reward-view/reward-view.component';
import { MeetingComponent } from './components/meeting/meeting.component';
import { ListDepartmentComponent } from './components/department/list-department/list-department.component';
import { AddDepartmentComponent } from './components/department/add-department/add-department.component';
import { ViewDepartmentComponent } from './components/department/view-department/view-department.component';
import { registerLocaleData } from '@angular/common';
import vi from '@angular/common/locales/vi';
import { SharedModule } from './shared/shared.module';
import { PockerComponent } from './components/pocker/pocker.component';
import { RoomDetailComponent } from './components/pocker/room-detail/room-detail.component';
import { WorkRemoteComponent } from './components/work-remote/work-remote.component';
import { ListCustomerComponent } from './components/customer/list-customer/list-customer.component';
import { AddCustomerComponent } from './components/customer/add-customer/add-customer.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NZ_I18N, vi_VN } from 'ng-zorro-antd/i18n';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { IconDefinition } from '@ant-design/icons-angular';
import { PockerService } from './services/pocker.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { DialogService } from './services/dialog.service';
import { NzTimePickerModule } from "ng-zorro-antd/time-picker";
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { CurrencyPipe } from '@angular/common';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { DetailProjectComponent } from './components/project/detail-project/detail-project.component';
registerLocaleData(vi);

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainLayoutComponent,
    UserProfileComponent,
    NotFoundComponent,
    AddUserComponent,
    SubmitOvertimeComponent,
    UsersComponent,
    ChangePasswordComponent,
    HomepageComponent,
    LeavingDetailComponent,
    SalaryDetailComponent,
    TextBoxComponent,
    SearchBoxComponent,
    UserProfileUpdateComponent,
    DropdownListComponent,
    SelectImageComponent,
    ResetPasswordComponent,
    ConfirmMailComponent,
    AppDatePickerComponent,
    // derectives
    InvalidTypeDirective,
    InvalidmessageDirective,
    PasswordValidator,
    MinValidator,
    MaxValidator,
    EqualValidator,
    MinDateValidator,
    PhoneValidator,
    //pipes
    ImagePipe,
    AppNumberPipe,
    ForgotPasswordComponent,
    SettingComponent,
    ConfirmDialogComponent,
    MessageDialogComponent,
    InputDialogComponent,
    AddProjectComponent,
    ListProjectComponent,
    DetailProjectComponent,
    OvertimeListComponent,
    ActivateUserComponent,
    CashAdvanceComponent,
    ViewProjectComponent,
    NotifyListComponent,
    OvertimeViewComponent,
    RewardListComponent,
    MaintainanceComponent,
    HomepageAdminComponent,
    RewardComposeComponent,
    RewardViewComponent,
    RateOverTimesComponent,
    MeetingComponent,
    ListDepartmentComponent,
    AddDepartmentComponent,
    ViewDepartmentComponent,
    PockerComponent,
    RoomDetailComponent,
    WorkRemoteComponent,
    ListCustomerComponent,
    AddCustomerComponent
  ],
  imports: [
    FullCalendarModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    TimepickerModule.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.chasingDots,
      backdropBackgroundColour: 'rgba(0,0,0,0.2)',
      backdropBorderRadius: '4px',
      primaryColour: '#59f2f7',
      secondaryColour: '#59f2f7',
      tertiaryColour: '#59f2f7',
      fullScreenBackdrop: false
    }),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    NzTableModule,
    SharedModule,
    NzDatePickerModule,
    NzDropDownModule,
    NzButtonModule,
    NzMenuModule,
    NzIconModule,
    NzTreeModule,
    NzSelectModule,
    NzModalModule,
    NzTimePickerModule,
    NzPaginationModule,
    NzDividerModule
  ],
  providers: [
    LoginAccessGuard,
    AdminAccessGuard,
    NzModalService,
    PockerService,
    NzNotificationService,
    ErrorService,
    GlobalService,
    RealtimeClientService,
    DialogService,
    BsModalRef,
    CurrencyPipe,
    { provide: NZ_I18N, useValue: vi_VN },
    { provide: NZ_ICONS, useValue: icons }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
