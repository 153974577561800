import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RoleUserEnum, RoleFilterEnum } from 'src/app/models/enums';
import { SendMessageModel } from 'src/app/models/sendmessage.model';
import { NotificationService } from 'src/app/services/notify.service';
import { UserIdModel, UserIdsModel } from '../../../models/user';
import { EmployeesInformation, UserShortInfo } from '../../../models/userinfo.model';
import { AuthService } from '../../../services/auth.service';
import { DialogService } from '../../../services/dialog.service';
import { SalaryService } from '../../../services/salary.service';
import { UserService } from '../../../services/user.service';
import { DropdownModel } from 'src/app/models/dropdown.model';
import { BsModalService } from 'ngx-bootstrap';
import { differenceInCalendarDays } from 'date-fns';
declare var $: any;
@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
    @ViewChild('modalExportSalary') modalExportSalary: TemplateRef<any>;
    dateGoBack: string;
    employees: UserShortInfo[] = [];
    tempEmployees: UserShortInfo[] = [];
    messageModel = new SendMessageModel;
    employeesInformation: EmployeesInformation = new EmployeesInformation();
    includeDeactivated: boolean = false;
    employeeFilter: DropdownModel;
    roleDropdown: DropdownModel[] = [];
    today = new Date();
    selectedMonth: Date;

    constructor(
        private notifyService: NotificationService,
        private userService: UserService,
        private dialogService: DialogService,
        private salaryService: SalaryService,
        private modal: BsModalService,
        private router: Router) { }

    isAdmin: boolean;
    isHR: boolean;

    disabledDate = (current: Date): boolean =>
        differenceInCalendarDays(current, this.today) > 0;

    ngOnInit() {
        this.roleDropdown = [
            { name: 'Tất cả nhân viên', value: RoleFilterEnum.All },
            { name: 'Nhân viên toàn thời gian', value: RoleFilterEnum.Employee },
            { name: 'Cộng tác viên (part time)', value: RoleFilterEnum.Collaborators },
            { name: 'Nhân viên bị khoá', value: RoleFilterEnum.Locked }
        ];
        this.isAdmin = AuthService.isAdmin();
        this.isHR = AuthService.isHR();
        if(this.isAdmin) {
            this.employeeFilter = this.roleDropdown.find(x => x.value === RoleFilterEnum.Employee);
        }
        this.getAllEmployeeInformation();
        this.getUsers();
    }

    getAllEmployeeInformation() {
        this.userService.getAllEmployeeInformation()
            .subscribe((res: EmployeesInformation) => {
                this.employeesInformation = res;
            })
    }

    searchUser(term) {
        this.userService.searchUsers(term)
            .subscribe(users => {
                this.tempEmployees = users;
                this.tempEmployees = this.tempEmployees.filter(emp => emp.role != RoleUserEnum.Administrator);
                if (!this.includeDeactivated) {
                  this.employees = this.tempEmployees.filter(emp=>!emp.isDeactive);
                }
            })
    }

    checkAllChanged(e) {
        this.employees.forEach(item => {
            item.isChecked = e.target.checked;
        });
    }

    getUsers(): void {
        this.userService.getUsers()
            .subscribe(apiUsers => {
                if (apiUsers) {
                    this.tempEmployees = apiUsers;
                    this.tempEmployees = this.tempEmployees.filter(emp => emp.role != RoleUserEnum.Administrator).sort((a, b) => (a.dateStartContract > b.dateStartContract) ?  1 : -1);
                    this.employees = apiUsers;
                    this.filterEmployees();
                }
            });

    }

    filterEmployees() {
        const filterValue = this.employeeFilter?.value; 
        switch (filterValue) {
        case RoleFilterEnum.All:
            this.employees = this.tempEmployees;
            break;
        case RoleFilterEnum.Employee:
            this.employees = this.tempEmployees.filter(emp=>emp.role == RoleFilterEnum.Employee && !emp.isDeactive);
            break;
        case RoleFilterEnum.Collaborators:
            this.employees = this.tempEmployees.filter(emp=>emp.role == RoleFilterEnum.Collaborators && !emp.isDeactive);
            break;
        case RoleFilterEnum.Locked:
            this.employees = this.tempEmployees.filter(emp=>emp.isDeactive);
            break;
        default:
            this.employees = this.tempEmployees;
            break;
        }
    }

    exportSalaryBank() {
      debugger;
        let ids: number[] = [];
        this.employees.forEach(item => {
            if (item.isChecked)
                ids.push(item.id);
        });
        if (ids.length <= 0) {
            this.notifyService.info("Chưa có nhân viên nào được chọn!");
            return;
        }
        this.userService.exportSalaryBank(ids);
    }

    exportSalaries() {
        let ids: number[] = [];
        this.employees.forEach(item => {
            if (item.isChecked)
                ids.push(item.id);
        });
        if (ids.length <= 0) {
            this.notifyService.info("Chưa có nhân viên nào được chọn!");
            return;
        }
        this.userService.exportSalary(ids,this.selectedMonth);
        this.modal.hide(1);
    }

    exportSalary(id: number, fullName: string) {
        let ids: number[] = [];
        ids.push(id);
        this.userService.exportSalaryWithUserName(ids, fullName, new Date());
    }

    exportContract(id: number, fullName: string) {
        this.userService.exportContractUser(id, fullName);
    }

    renewContract(id: number) {
        let model = new UserIdModel();
        model.userId = id;

        this.dialogService.confirm("Làm mới hợp đồng", "Bạn có chắc muốn làm mới hợp đồng của nhân viên này?", () => {
            this.userService.renewContract(model)
                .subscribe(data => {
                    if (data) {
                        this.dialogService.message("Thông báo", "Làm mới hợp đồng thành công, hợp đồng đã được thay đổi!!!", () => {
                        }, "Đóng");
                    }
                });
        });
    }
    
    exportSubContract(id: number) {
        this.userService.exportSubContract(id);
    }

    lockSalary(user: UserShortInfo) {
        let ids: number[] = [];
        ids.push(user.id);
        this.dialogService.confirm("Khóa sổ", "Bạn có chắc muốn khóa sổ nhân viên này?", () => {
            this.salaryService.lockSalary(ids).subscribe(data => {
                if (data) {
                    this.getUsers();
                    this.getAllEmployeeInformation();
                }
                this.dialogService.message("Thông báo", "Bạn đã khóa sổ nhân viên " + user.fullName + " thành công!!!", () => {
                }, "Đóng");
            });
        });

    }

    lock(id: number) {
        let model = new UserIdsModel();
        model.userIds.push(id);
        model.isDeactive = true;
        this.dialogService.confirm("Khóa nhân viên", "Bạn có chắc muốn khóa nhân viên này?", () => {
            this.userService.deactivateUser(model)
                .subscribe(data => {
                    if (data) {
                        this.getUsers();
                        this.getAllEmployeeInformation();
                    }
                    this.dialogService.message("Khoá nhân viên", "Bạn đã khóa nhân viên thành công!!!", () => {
                    }, "Đóng");
                });
        });
    };

    lockEmployees() {
        let model = new UserIdsModel();
        model.isDeactive = true;
        this.employees.forEach(item => {
            if (item.isChecked)
                model.userIds.push(item.id);
        });
        if (model.userIds.length <= 0) {
            this.notifyService.info("Chưa có nhân viên nào được chọn!");
            return;
        }
        this.dialogService.confirm("Khóa nhân viên", "Bạn có chắc muốn khóa những nhân viên này?", () => {
            this.userService.deactivateUser(model)
                .subscribe(data => {
                    if (data)
                        this.getAllEmployeeInformation();
                        this.getUsers();
                });
        });
    };

    removeEmployees() {
        let model = new UserIdsModel();
        this.employees.forEach(item => {
            if (item.isChecked)
                model.userIds.push(item.id);
        });
        if (model.userIds.length <= 0) {
            this.notifyService.info("Chưa có nhân viên nào được chọn!");
            return;
        }
        this.dialogService.confirm("Xóa nhân viên", "Bạn có chắc muốn xóa những nhân viên này?", () => {
            this.userService.removeUser(model)
                .subscribe(data => {
                    if (data)
                        this.getUsers();
                        this.getAllEmployeeInformation();
                    this.dialogService.message("Xóa nhân viên", "Bạn đã xóa thành công!!!", () => {
                    }, "Đóng");
                });
        });
    };

    sendMessages() {
        let ids: number[] = [];
        this.employees.forEach(item => {
            if (item.isChecked)
                ids.push(item.id);
        });

        if (ids.length <= 0) {
            this.notifyService.info("Chưa có nhân viên nào được chọn!");
            return;
        }

        this.messageModel.userIds = ids;

        this.dialogService.formModal("Gửi tin nhắn", " ", "nội dung tin nhắn...", (result: string) => {
            this.messageModel.messages = result;
            this.userService.sendMessage(this.messageModel)
                .subscribe(data => {
                    if (data) {
                        this.dialogService.message("Thông báo", "Gửi tin nhắn thành công!!!", () => {
                        }, "Đóng");
                    }
                });
        });
    };

    unlock(id: number) {
        let model = new UserIdsModel();
        model.userIds.push(id);
        model.isDeactive = false;
        this.dialogService.confirm("Mở khóa nhân viên", "Bạn có chắc muốn mở khóa nhân viên này?", () => {
            this.userService.deactivateUser(model)
                .subscribe(data => {
                    if (data) {
                        this.getUsers();
                        this.getAllEmployeeInformation();
                        this.dialogService.message("Thông báo", "Bạn đã mở khóa thành công!!!", () => {
                        }, "Đóng");
                    }
                });
        });
    }

    unlockLogin(id: number) {
        let model = new UserIdModel();
        model.userId = id;
        this.dialogService.confirm("Mở khóa đăng nhập", "Bạn có chắc muốn mở khóa đăng nhập nhân viên này?", () => {
            this.userService.unlockLogin(model)
                .subscribe(data => {
                    if (data) {
                        this.getUsers();
                        this.getAllEmployeeInformation();
                        this.dialogService.message("Thông báo", "Bạn đã mở khóa thành công!!!", () => {
                        }, "Đóng");
                    }
                });
        });
    }

    resetPasscode(id: number) {
        let model = new UserIdModel();
        model.userId = id;
        this.dialogService.confirm("Đặt lại mật khẩu", "Bạn có chắc muốn đặt lại mật khẩu nhân viên này?", () => {
            this.userService.resetPasscode(model)
                .subscribe(data => {
                    if (data) {
                        this.getUsers();
                        this.dialogService.message("Thông báo", "Đặt lại mật khẩu thành công!!!", () => {
                        }, "Đóng");
                    }
                });
        });
    }

    remove(id: number) {
        let model = new UserIdsModel();
        model.userIds.push(id);
        this.dialogService.confirm("Xóa nhân viên", "Bạn có chắc muốn xóa nhân viên này?", () => {
            this.userService.removeUser(model)
                .subscribe(data => {
                    if (data) {
                        this.getUsers();
                        this.getAllEmployeeInformation();
                        this.dialogService.message("Xóa nhân viên", "Bạn đã xóa thành công!!!", () => {
                        }, "Đóng");
                    }
                });
        });
    }

    sendMessage(id: number) {
        this.messageModel.userIds = [];
        this.messageModel.userIds.push(id);

        this.dialogService.formModal("Gửi tin nhắn", " ", "Nội dung tin nhắn...", (result: string) => {

            this.messageModel.messages = result;

            this.userService.sendMessage(this.messageModel)
                .subscribe(data => {
                    if (data) {

                        this.dialogService.message("Thông báo", "Gửi tin nhắn thành công!!!", () => {

                            this.dialogService.hideModal();

                        }, "Đóng");
                        this.dialogService.hideModal();
                    }
                });
        });
    }

    goToProfile(e, id: number) {
        if (e.target.localName === 'a' || e.target.parentElement.localName === 'a'
            || e.target.localName === 'button' || e.target.parentElement.localName === 'button'
            || e.target.localName === 'input') {
            return;
        }
        this.router.navigate(['user-profile', id]);
    }

    goToSalary(id: number) {
        this.router.navigate(['salary-detail', id]);
    }

    showModalExportSalary(){
        this.modal.show(this.modalExportSalary, {
          ignoreBackdropClick: true
        });
        this.selectedMonth = new Date();
    }

    closeModalExportSalary(): void {
        this.modal.hide(1);
    }

    recalculateDayOffs(id: number) {
        this.dialogService.confirm("Tính lại ngày phép", "Bạn có chắc muốn tính lại ngày phép cho nhân viên này?", () => {
            this.userService.recalculateDayOffs(id)
                .subscribe(data => {
                    if (data) {
                        this.dialogService.message("Tính lại ngày phép", "Bạn đã tính lại ngày phép cho nhân viên này thành công!!!", () => {
                        }, "Đóng");
                    }
                });
        });
    }
}
