enum UserRole {
    Employee = 1,
    Admin = 2
}
enum Gender {
    Male = 1,
    Female = 2,
    Other = 3
}

enum HolidayType {
    Certain = 1,
    Uncertain = 2,
    CompanySpecified = 3,
}

enum SpecialDayType {
    None = 0,
    DayOff = 1,
    Holiday = 2,
    Makeup = 3,
    Weekend = 4,
    Remote = 5
}

enum DayoffStatus {
    Approve = 1,
    Waiting = 2,
    Reject = 3
}

enum DayoffStatusName_Vi {
    Approve = "Đã được xác nhận",
    Waiting = "Đang đợi xác nhận",
    Reject = "Đã bị từ chối",
    Urgent = "Nghỉ khẩn cấp",
    WaitingDelete = "Đang đợi xác nhận xoá"
}

enum MaritalStatus {
    Single = 1,
    Married = 2,
    Relationship = 3,
}
enum EmployeeTypes { Officially, InterShip, Probationary };
enum WorkingStates { Offline, Working };
enum DayOffOption {
    FullDay = 1,
    Morning = 2,
    Afternoon = 3
}
enum NotifyType {
    Success,
    Info,
    Warning,
    Error
}

enum DialogStyles {
    None,
    Message,
    Input,
    ThreeOptions,
    Confirm
}

enum AlertGroups {
    Message = 0,
    Account,
    Project,
    DayOff,
    OverTime,
    BirthDay,
    Celebrator,
    CashAdvance,
    All,
    AllRead,
    AllUnread,
}

enum ColorForShow {
    DayOffFullDay = 'lightseagreen',
    DayOffHalfDay = 'yellow',
    HolidayCertain = 'yellow',
    HolidayUncertain = 'blue',
    HolidayCompany = 'green',
    Makeup = 'red',
    DayoffNotApproved = 'pink'
}

enum MenuItems {
    None = 'None',
    Home = 'Home',
    Users = 'Users',
    Project = 'Project',
    DayOff = 'DayOff',
    OverTime = 'OverTime',
    CashAdvance = 'CashAdvance',
    Reward = 'Reward',
    Feedback = 'Feedback',
    Salary = 'Salary',
    Record = 'Record',
    Signature = 'Signature',
    PointOfView = 'PointOfView',
    Setting = 'Setting',
    Notification = 'Notification',
    Forms = 'Forms',
    RateOvertime = 'RateOverTime',
    Meeting = 'Meeting',
    Policy = 'Policy',
    Department = 'Department',
    WorkRemote = 'WorkRemote',
    Customer = 'Customer'
}

enum CashAdvanceStatus {
    None = 0,
    Approve = 1,
    Reject = 2,
    Waiting = 3
}
enum RewardStatus {
    None = 0,
    Sent,
    Draft
}
enum PatternTemplateEnum {
    LaborContract = 1,
    ProbationaryContract = 2,
    SalaryTemplate = 3,
    SalaryBankTemplate = 4,
    SubContract = 5,
    CollaboratorContract = 6
}

enum ChangeDateButtonEnum {
    Previous = 'previous',
    Next = 'next',
    Today = 'today'
}

enum UserPosition {
    Administrator = 1,
    Manager,
    Employee,
    Probationer,
    Interns,
    Collaborators
}

enum DepartmentStatus {
    All = "0",
    Activate = "1",
    Deactivate = "2",
    ComingSoon = "3"
}

enum DepartmentSearchBy {
    DepartmentName = "0",
    Status = "1"
}

enum DepartmentRole {
    None = 0,
    Manager,
    Deputy,
    Member
}

enum RoleUserEnum {
    Administrator = 1,
    Manager,
    Employee,
    Probationer,
    Interns,
    Collaborators,
    Freelance
}

enum RoleFilterEnum {
    All = 0,
    Employee = 3,
    Collaborators = 6,
    Locked = 8
}

enum CurrencyType {
    USD = 1,
    VND = 2
}

enum SalaryType {
    Net = 1,
    Gross = 2,
    Parttime = 3,
    Probation = 4
}

enum UploadType {
    Logo = 'logo',
    LogonLogo = 'logonLogo',
    Background = 'background'
}

export {
    MenuItems, CashAdvanceStatus, PatternTemplateEnum, UserPosition, DepartmentSearchBy,
    UserRole, Gender, MaritalStatus, EmployeeTypes, ChangeDateButtonEnum, DepartmentStatus,
    WorkingStates, NotifyType, DialogStyles, AlertGroups, DayoffStatusName_Vi, DepartmentRole,
    SpecialDayType, HolidayType, ColorForShow, DayOffOption, DayoffStatus, RewardStatus, RoleUserEnum,
    CurrencyType, SalaryType, UploadType, RoleFilterEnum
};
